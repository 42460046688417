<!-- BudgetsTopFilterSection.vue -->
<script setup>
import { ref, onMounted, watch } from 'vue';
import { useGlobalStore } from '@/stores/GlobalStore';
import { usePropertiesStore } from '@/stores/PropertiesStore';
import { useBudgetStore } from '@/stores/BudgetStore';

import AppIconAngleDownPair from '@/components/svg/AppIconAngleDownPair.vue';
import AppButton from '@/components/ui/buttons/AppButton.vue';
import AppSingleSelector from '@/components/ui/selects/AppSingleSelector.vue';
import AppVisibleFilterWrapper from '@/components/ui/AppVisibleFilterWrapper.vue';
import BudgetOptionsSelectorFooter from '@/components/budgets/top-filter-section-components/SelectorFooter.vue';

import { budgetOptionsData, budgetYearOptionsData } from '@/data/budgets.js';

// Global store for filter visibility toggling
const globalStore = useGlobalStore();
// Properties store (for listing properties)
const propertiesStore = usePropertiesStore();
// Budget store for applying or resetting filters
const budgetStore = useBudgetStore();

// Local refs
const originalProperties = ref([]);
const selectedProperty = ref('');
const selectedBudgets = ref('');
const selectedYear = ref('');
const reset = ref(false); // Toggles to show or hide "Reset" link

// Toggle top filter in mobile/responsive
function handleClick() {
  globalStore.toggleVisibleFilter();
}

// On mount, fetch properties & set default year
onMounted(async () => {
  await propertiesStore.getProperties();
  originalProperties.value = propertiesStore.originalProperties;

  const currentYear = new Date().getFullYear();
  selectedYear.value = currentYear.toString();
});

/**
 * transformOption:
 * Each property item becomes:
 * {
 *   label: attributes.name,
 *   value: attributes.id,
 *   and anything else we need
 * }
 * So the user sees `label` in the dropdown, but when selected, `selectedProperty.value` is the numeric ID.
 */
function transformOption(option) {
  const { attributes, ...rest } = option;
  if (!attributes) {
    return {
      ...rest,
      name: 'Unknown',
      value: rest.id || 'Unknown',
      label: 'Unknown',
    };
  }
  return {
    ...rest,
    ...attributes,
    label: attributes.name,
    name: attributes.name,
    value: attributes.id,
  };
}

// Reset logic: clears store & local states
function handleReset() {
  budgetStore.resetFilters();
  selectedProperty.value = '';
  selectedBudgets.value = '';
  selectedYear.value = '';
  reset.value = false;
}

// Watching for store resets or changes
watch(
  () => budgetStore.searchPerformed,
  (newVal, oldVal) => {
    // If our searchPerformed is now false, it means resetFilters() was called
    if (newVal === false) {
      selectedProperty.value = '';
      selectedBudgets.value = '';
      selectedYear.value = '';
    }
  }
);

/**
 * handleView:
 * 1) `selectedProperty.value` is an ID (e.g. 1136).
 * 2) We find the matching property in `originalProperties`, then get its label (e.g. "1000 South Capitol").
 * 3) We store that label in `budgetStore.selectedPropertyName`, so the second breadcrumb uses the label.
 */
function handleView() {
  // Step 1: user picked an ID, e.g. 1136
  const chosenId = selectedProperty.value;

  // Step 2: find the property object with that ID
  const chosenObj = originalProperties.value.find((item) => {
    // item.id or item.value might hold the numeric ID.
    // For safety, check item.id or item.attributes?.id.
    if (item.attributes) {
      return item.attributes.id === chosenId;
    } else {
      return item.id === chosenId;
    }
  });

  // Step 3: store the property label in the store
  // if found. Otherwise fallback to the numeric ID
  let finalLabel = chosenId;
  if (chosenObj) {
    // e.g. "1000 South Capitol"
    finalLabel = chosenObj.attributes?.name || chosenObj.label || chosenId;
  }

  // Now the store has the actual property label
  budgetStore.selectedPropertyName = finalLabel;

  // If you want the Budgets dropdown & year to remain strings, store them as is
  budgetStore.selectedBudgetName = selectedBudgets.value;
  budgetStore.selectedYear = selectedYear.value;

  // Apply filters, causing the store to do its final logic & breadcrumbs update
  budgetStore.applyFilters();
  reset.value = true;
}
</script>

<template>
  <div class="styles_topFilter__container">
    <div class="grid">
      <!-- Property Selector (displays label, returns ID in v-model="selectedProperty") -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector
          body-width="300px"
          title="Properties"
          placeholder="Select a property"
          :options="originalProperties.map(transformOption)"
          :show-search="true"
          v-model="selectedProperty"
        />
      </AppVisibleFilterWrapper>

      <!-- Budget Dropdown -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector
          title="Budget"
          placeholder="Select a budget"
          :options="budgetOptionsData"
          v-model="selectedBudgets"
          :show-search="true"
          footer-link="/properties"
          footer-text="New budget"
        >
          <template #footer>
            <BudgetOptionsSelectorFooter />
          </template>
        </AppSingleSelector>
      </AppVisibleFilterWrapper>

      <!-- Year Selector -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector title="Year" placeholder="Select a year" :options="budgetYearOptionsData" v-model="selectedYear" :show-search="true" />
      </AppVisibleFilterWrapper>

      <!-- Filter Buttons -->
      <div class="filter-button-container">
        <!-- Show "Reset" if reset is true, else placeholder to keep spacing -->
        <div class="filter-clear-all" v-if="reset" @click="handleReset">Reset</div>
        <div v-else class="filter-clear-all-placeholder"></div>

        <AppButton label="View" class="filter-button-bottom" @click="handleView" />
      </div>

      <!-- Toggle for mobile usage -->
      <AppButton toggle_btn @click="handleClick">
        <AppIconAngleDownPair />
      </AppButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../styles/settings/mixins';

.grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.filter-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-clear-all,
.filter-clear-all-placeholder {
  color: var(--secondary-color);
  background-color: transparent;
  border: none;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  margin-bottom: 5px;
  transition: all 0.3s ease;
  text-decoration: underline;
  height: 15px;
  visibility: hidden;
}

.filter-clear-all {
  visibility: visible;
  text-decoration: underline;

  &:hover {
    color: var(--primary-text-color);
    cursor: pointer;
  }

  &:active {
    opacity: 0.7;
  }
}
</style>
