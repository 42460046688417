<!-- AppLeasingFunnelBar.vue -->
<script setup>
// Imports...
import AppBarChart from '@/components/ui/charts/AppBarChart.vue';
import LeasingFunnelBarData from '@/data/leasing-data/AppLeasingFunnelBarData.json';
import { reactive } from 'vue';
// Logic...
const chartData = reactive(LeasingFunnelBarData);

const handleUpdateChartData = (newChartData) => {
  Object.assign(chartData, newChartData);
};
</script>

<template>
  <AppBarChart :chart-data="chartData" @update:chart-data="handleUpdateChartData" chart-max-height="288px" />
</template>

<style scoped lang="scss"></style>
