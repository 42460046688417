<!-- IntegrationsPageHeader.vue -->
<script setup>
import { computed, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { useSettingsStore } from '@/stores/SettingsStore';
import AppBreadcrumbs from '@/components/ui/AppBreadcrumbs.vue';
import AppOverlayMenu from '@/components/ui/AppOverlayMenu.vue';
import AppIconDotsVertical from '@/components/svg/AppIconDotsVertical.vue';
import AppIconBoxWrapper from '@/components/wrappers/AppIconBoxWrapper.vue';

const settingsStore = useSettingsStore();

const isOverlayVisible = ref(false);
const target = ref(null);

function toggleOverlay() {
  isOverlayVisible.value = !isOverlayVisible.value;
}

onClickOutside(target, () => {
  if (isOverlayVisible.value) {
    isOverlayVisible.value = false;
  }
});

function handleOptionClicked(option) {
  console.log(`Option ${option} clicked`);
}

// Breadcrumb items computed based on applied filters
const breadcrumbItems = computed(() => {
  const items = [{ text: 'Reset', action: () => settingsStore.resetSearch() }];

  if (settingsStore.appliedProperty) {
    items.push({ text: settingsStore.appliedProperty });
  }
  if (settingsStore.appliedSetting) {
    items.push({ text: settingsStore.appliedSetting });
  }
  return items;
});
</script>

<template>
  <div class="styles_pageHeader__section">
    <div class="styles_pageHeader__container">
      <div>
        <transition name="fade-breadcrumbs">
          <AppBreadcrumbs v-if="settingsStore.searchPerformed" :items="breadcrumbItems" :key="JSON.stringify(breadcrumbItems)" />
        </transition>
        <h3 class="page-title">Settings</h3>
      </div>
      <div class="styles_pageHeader__icon-container">
        <div ref="target">
          <AppIconBoxWrapper @click="toggleOverlay">
            <AppIconDotsVertical />
          </AppIconBoxWrapper>
          <AppOverlayMenu v-if="isOverlayVisible" @option-clicked="handleOptionClicked" :options="['export', 'import', 'settings']" bodyWidth="210px">
            <template #export>
              <div>Export Integration Report</div>
            </template>
            <template #import>
              <div>Import Option</div>
            </template>
            <template #settings>
              <div>Settings Option</div>
            </template>
          </AppOverlayMenu>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'src/styles/settings/_mixins.scss';
@import 'src/styles/settings/_themes.scss';

.page-title {
  margin-bottom: 8px;
}

/* Transition classes for fade */
.fade-breadcrumbs-enter-active,
.fade-breadcrumbs-leave-active {
  transition: opacity 0.3s ease;
}

.fade-breadcrumbs-enter-from,
.fade-breadcrumbs-leave-to {
  opacity: 0;
}
</style>
