// BudgetStore.js
import { defineStore } from 'pinia';

export const useBudgetStore = defineStore('BudgetStore', {
  state: () => ({
    currentBudgetView: 'table',

    // "Instant" states: user picks these but hasn't applied yet
    selectedPropertyName: '',
    selectedBudgetName: '',
    selectedYear: '',

    // "Applied" states: final chosen data for breadcrumbs/UI
    appliedPropertyName: '',
    appliedBudgetName: '',
    appliedYear: '',

    // Flag to indicate if filters have been applied
    searchPerformed: false,
  }),

  actions: {
    toggleView(view) {
      this.currentBudgetView = view;
      localStorage.setItem('currentBudgetView', view);
    },

    loadInitialView() {
      this.currentBudgetView = localStorage.getItem('currentBudgetView') || 'table';
    },

    applyFilters() {
      // Toggle off -> on for reactivity
      this.searchPerformed = false;

      this.appliedPropertyName = this.selectedPropertyName;
      this.appliedBudgetName = this.selectedBudgetName;
      this.appliedYear = this.selectedYear;

      this.searchPerformed = true;
    },

    // Reset all filters
    resetFilters() {
      this.selectedPropertyName = '';
      this.selectedBudgetName = '';
      this.selectedYear = '';

      this.appliedPropertyName = '';
      this.appliedBudgetName = '';
      this.appliedYear = '';

      this.searchPerformed = false;
    },
  },
});
