<script setup>
import { ref, onMounted, onUnmounted, watch, reactive, nextTick, computed, watchEffect } from 'vue';
import { Chart, registerables } from 'chart.js';
import { useThemeStore } from '@/stores/ThemeStore.js';

const props = defineProps({
  chartData: {
    type: Object,
    required: true,
  },
  chartOptions: {
    type: Object,
    default: () => ({}),
  },
  chartMaxHeight: {
    type: String,
    default: '300px',
  },
  pillText: {
    type: String,
    default: null,
  },
  includeTabs: {
    type: Boolean,
    default: false,
  },
  toggleTabFunction: {
    type: Function,
    required: false,
  },
  tabs: {
    type: Array,
    default: () => [],
  },
  activeTab: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:chartData']);
const themeStore = useThemeStore();
Chart.register(...registerables);

const getThemeColor = () => {
  const borderColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color').trim();
  const backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color').trim();
  return { borderColor, backgroundColor };
};

const chartCanvasRef = ref(null);
let chartInstance;

const localChartData = reactive({ ...props.chartData });

const updateChartColors = () => {
  const { borderColor, backgroundColor } = getThemeColor();
  if (chartInstance && chartCanvasRef.value) {
    chartInstance.data.datasets.forEach((dataset) => {
      dataset.borderColor = borderColor;
      dataset.backgroundColor = backgroundColor;
      dataset.maxBarThickness = 75;
    });
    chartInstance.update();
  } else {
    // Use these values in case the chartInstance is not yet available
    localChartData.datasets[0].borderColor = borderColor;
    localChartData.datasets[0].backgroundColor = backgroundColor;
  }
};

const defaultChartOptions = reactive({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { position: 'none' },
  },
  scales: {
    x: {
      stacked: true,
      grid: { display: false },
      ticks: { color: '' },
    },
    y: {
      stacked: true,
      border: { display: false },
      grid: { color: '' },
      ticks: {
        color: '',
        maxTicksLimit: 6,
      },
    },
  },
  animations: false,
  layout: { padding: { left: 50, right: 50, top: 40, bottom: 30 } },
});

watchEffect(() => {
  defaultChartOptions.scales.x.ticks.color = getComputedStyle(document.documentElement).getPropertyValue('--secondary-color').trim();
  defaultChartOptions.scales.y.grid.color = getComputedStyle(document.documentElement).getPropertyValue('--secondary-color-muted').trim();
  defaultChartOptions.scales.y.ticks.color = getComputedStyle(document.documentElement).getPropertyValue('--secondary-color').trim();
});

const handleChartOptions = computed(() => {
  // Check if the user provided options are empty (default case)
  if (Object.keys(props.chartOptions).length === 0) {
    return defaultChartOptions;
  } else {
    // If user provides their own options, use them entirely
    return props.chartOptions;
  }
});

onMounted(() => {
  nextTick(() => {
    if (!chartCanvasRef.value) return;

    const ctx = chartCanvasRef.value.getContext('2d');
    if (!ctx) return;

    updateChartColors();

    try {
      chartInstance = new Chart(ctx, {
        type: 'bar',
        data: localChartData,
        options: handleChartOptions.value,
      });
    } catch (error) {
      console.error('Error initializing chart:', error);
    }

    watch(
      () => themeStore.selectedTheme,
      () => {
        updateChartColors();
      },
      { immediate: true }
    );
  });
});

onUnmounted(() => {
  if (chartInstance) {
    chartInstance.destroy();
  }
});

watch(
  () => localChartData,
  (newChartData) => {
    emit('update:chartData', newChartData);
  },
  { deep: true }
);

const cssVariables = computed(() => {
  return {
    '--chart-max-height': props.chartMaxHeight,
  };
});
</script>

<template>
  <div class="chart-container" :style="cssVariables">
    <div class="chart-header-container">
      <h2>{{ localChartData.title }}</h2>
      <div class="tabs-container">
        <a
          v-for="tab in tabs"
          :key="tab.value"
          v-show="includeTabs"
          @click="toggleTabFunction(tab)"
          :class="{ 'active-tab': activeTab === tab.value }"
        >
          {{ tab.name }}
        </a>
      </div>
    </div>
    <div class="chart-bar">
      <canvas ref="chartCanvasRef" class="chart-canvas"></canvas>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.chart-container {
  width: 100%;
  height: 100%;
  border-radius: 18px;
  border: var(--border-size) solid var(--secondary-color);

  &:hover {
    border: var(--border-size) solid var(--secondary-color);
  }
}

.chart-bar {
  width: 100%;
  height: var(--chart-max-height);
}

.chart-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: var(--border-size) solid var(--secondary-color);

  h2 {
    margin: 0;
    padding: 15px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: var(--primary-text-color-rgb);
  }

  .pill-content-container {
    padding: 15px;

    .pill {
      background-color: var(--secondary-color);
      color: var(--primary-text-color);
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .tabs-container {
  display: flex;
  gap: 10px;
  padding: 15px;
}

.active-tab {
  color: #42b983;
  border-bottom: 2px solid #42b983;
  font-weight: bold;
}
}
</style>
