// Imports...
import { defineAsyncComponent, markRaw } from 'vue';
import LeasingFunnelBar from '../components/widget-library/leasing/LeasingFunnelBar.vue';

/**
 * Retrieves a component by its name from the component registry.
 *
 * @param {string} name - The name of the component to retrieve.
 * @return {Component | null} The component if found, or null if not found.
 * @throws {Error} If the component is not found and the name is invalid.
 * @description Please note the name must match the string name in the widget-cards/example.vue
 * component to work with the widget cards
 * @example {componentRegistry name} -
 * AvgSessionTimeMetric: () => import('@/components/widget-library/ga4/AvgSessionTimeMetric.vue'),
 * @example {usage in widget-cards/Ga4.js} -  {
 *     title: 'Average Session Time',
 *     icon: icons.AppGa4Icon,
 *     type: 'Metric',
 *     description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
 *     preview: previews.AppWidgetPreview,
 *     integration: 'Google Analytics 4',
 *     i: '3',
 *     component: 'AvgSessionTimeMetric',
 *     ...smallWidget,
 *   }
 *
 */
const componentRegistry = {
  // GA4 Components
  AvgSessionTimeMetric: () => import('@/components/widget-library/ga4/AvgSessionTimeMetric.vue'),
  BounceRateMetric: () => import('@/components/widget-library/ga4/BounceRateMetric.vue'),
  BrowsersDonut: () => import('@/components/widget-library/ga4/BrowsersDonut.vue'),
  CampaignTrafficTable: () => import('@/components/widget-library/ga4/CampaignTrafficTable.vue'),
  KeyEventActivityLine: () => import('@/components/widget-library/ga4/KeyEventActivityLine.vue'),
  KeyEventRateMetric: () => import('@/components/widget-library/ga4/KeyEventRateMetric.vue'),
  Ga4KeyEventsMetric: () => import('@/components/widget-library/ga4/KeyEventsMetric.vue'),
  KeyEventSourcesTable: () => import('@/components/widget-library/ga4/KeyEventSourcesTable.vue'),
  DevicesDonut: () => import('@/components/widget-library/ga4/DevicesDonut.vue'),
  LandingPagesTable: () => import('@/components/widget-library/ga4/LandingPagesTable.vue'),
  PagesPerSessionMetric: () => import('@/components/widget-library/ga4/PagesPerSessionMetric.vue'),
  PercentageNewSessionsMetric: () => import('@/components/widget-library/ga4/PercentageNewSessionsMetric.vue'),
  SessionsMetric: () => import('@/components/widget-library/ga4/SessionsMetric.vue'),
  SocialTrafficTable: () => import('@/components/widget-library/ga4/SocialTrafficTable.vue'),
  TopPagesTable: () => import('@/components/widget-library/ga4/TopPagesTable.vue'),
  TrafficActivityLine: () => import('@/components/widget-library/ga4/TrafficActivityLine.vue'),
  TrafficSourcesTable: () => import('@/components/widget-library/ga4/TrafficSourcesTable.vue'),
  TrendingKeyEventsBar: () => import('@/components/widget-library/ga4/TrendingKeyEventsBar.vue'),
  TrendingSessionsBar: () => import('@/components/widget-library/ga4/TrendingSessionsBar.vue'),
  VisitorLocationsTable: () => import('@/components/widget-library/ga4/VisitorLocationsTable.vue'),
  // Google Ads Components
  AvgCpcLine: () => import('@/components/widget-library/google-ads/AvgCpcLine.vue'),
  AvgCtrLine: () => import('@/components/widget-library/google-ads/AvgCtrLine.vue'),
  ClickConvActivityLine: () => import('@/components/widget-library/google-ads/ClickConvActivityLine.vue'),
  AdsClicksMetric: () => import('@/components/widget-library/google-ads/ClicksMetric.vue'),
  ClicksBar: () => import('@/components/widget-library/google-ads/ClicksBar.vue'),
  ClickThroughRateMetric: () => import('@/components/widget-library/google-ads/ClickThroughRateMetric.vue'),
  AdsConversionsMetric: () => import('@/components/widget-library/google-ads/ConversionsMetric.vue'),
  AdsConversionsLine: () => import('@/components/widget-library/google-ads/ConversionsLine.vue'),
  ConvRateMetric: () => import('@/components/widget-library/google-ads/ConvRateMetric.vue'),
  CostMetric: () => import('@/components/widget-library/google-ads/CostMetric.vue'),
  CostPerClickMetric: () => import('@/components/widget-library/google-ads/CostPerClickMetric.vue'),
  AdsCostPerConvMetric: () => import('@/components/widget-library/google-ads/CostPerConvMetric.vue'),
  AdsImpressionsMetric: () => import('@/components/widget-library/google-ads/ImpressionsMetric.vue'),
  ImpressionShareTable: () => import('@/components/widget-library/google-ads/ImpressionShareTable.vue'),
  KeywordsSearchQTable: () => import('@/components/widget-library/google-ads/KeywordsSearchQTable.vue'),
  SearchAdGroupsTable: () => import('@/components/widget-library/google-ads/SearchAdGroupsTable.vue'),
  SearchAdsTable: () => import('@/components/widget-library/google-ads/SearchAdsTable.vue'),
  SearchCampaignsTable: () => import('@/components/widget-library/google-ads/CampaignsTable.vue'),
  TotalCostLine: () => import('@/components/widget-library/google-ads/TotalCostLine.vue'),
  // Meta Ads Components
  ConversionsMetric: () => import('@/components/widget-library/meta-ads/ConversionsMetric.vue'),
  ClicksLine: () => import('@/components/widget-library/meta-ads/ClicksLine.vue'),
  ConversionsLine: () => import('@/components/widget-library/meta-ads/ConversionsLine.vue'),
  SpendMetric: () => import('@/components/widget-library/meta-ads/SpendMetric.vue'),
  CostPerConvMetric: () => import('@/components/widget-library/meta-ads/CostPerConvMetric.vue'),
  CampaignsTable: () => import('@/components/widget-library/meta-ads/CampaignsTable.vue'),
  DemographicsDonut: () => import('@/components/widget-library/meta-ads/DemographicDonut.vue'),
  GenderDonut: () => import('@/components/widget-library/meta-ads/GenderDonut.vue'),
  PlacementPerformanceTable: () => import('@/components/widget-library/meta-ads/PlacementPerformanceTable.vue'),
  CreativePerformanceTable: () => import('@/components/widget-library/meta-ads/CreativePerformanceTable.vue'),
  ImpressionsMetric: () => import('@/components/widget-library/meta-ads/ImpressionsMetric.vue'),
  // Leasing Components
  GuestCardsMetric: () => import('@/components/widget-library/leasing/GuestCardsMetric.vue'),
  ApplicationsMetric: () => import('@/components/widget-library/leasing/ApplicationsMetric.vue'),
  LeasesMetric: () => import('@/components/widget-library/leasing/LeasesMetric.vue'),
  LeaseRateMetric: () => import('@/components/widget-library/leasing/LeaseRateMetric.vue'),
  GuestCardSourcesTable: () => import('@/components/widget-library/leasing/GuestCardSourcesTable.vue'),
  GuestCardsActivityLine: () => import('@/components/widget-library/leasing/GuestCardsActivityLine.vue'),
  ShowsGuestCardsMetric: () => import('@/components/widget-library/leasing/ShowsGuestCardsMetric.vue'),
  ApplicationsShowMetric: () => import('@/components/widget-library/leasing/ApplicationsShowMetric.vue'),
  LeasesShowsMetric: () => import('@/components/widget-library/leasing/LeasesShowsMetric.vue'),
  AgentsTable: () => import('@/components/widget-library/leasing/AgentsTable.vue'),
  GuestCardsBar: () => import('@/components/widget-library/leasing/GuestCardsBar.vue'),
  ApplicationsBar: () => import('@/components/widget-library/leasing/ApplicationsBar.vue'),
  LeasesBar: () => import('@/components/widget-library/leasing/LeasesBar.vue'),
  LeasingFunnelBar: () => import('@/components/widget-library/leasing/LeasingFunnelBar.vue'),
  // Availability Components
  ExposureMetric: () => import('@/components/widget-library/availability/ExposureMetric.vue'),
  FloorPlansTable: () => import('@/components/widget-library/availability/FloorPlansTable.vue'),
  LeasedMetric: () => import('@/components/widget-library/availability/LeasedMetric.vue'),
  OccupancyDonut: () => import('@/components/widget-library/availability/OccupancyDonut.vue'),
  OccupiedMetric: () => import('@/components/widget-library/availability/OccupiedMetric.vue'),
  TrendingOccupancyBar: () => import('@/components/widget-library/availability/TrendingOccupancyBar.vue'),
  UnitsTable: () => import('@/components/widget-library/availability/UnitsTable.vue'),
  UnitsAvailableMetric: () => import('@/components/widget-library/availability/UnitsAvailableMetric.vue'),
  UnitTypesTable: () => import('@/components/widget-library/availability/UnitTypesTable.vue'),
  AvailabilityDonut: () => import('@/components/widget-library/availability/AvailabilityDonut.vue'),
  // Google Business Profile Components
  ActivityDonut: () => import('@/components/widget-library/google-business-profile/ActivityDonut.vue'),
  GoogleSearchMetric: () => import('@/components/widget-library/google-business-profile/GoogleSearchMetric.vue'),
  TotalViewsMetric: () => import('@/components/widget-library/google-business-profile/TotalViewsMetric.vue'),
  TotalActivityMetric: () => import('@/components/widget-library/google-business-profile/TotalActivityMetric.vue'),
  WebsiteClicksMetric: () => import('@/components/widget-library/google-business-profile/WebsiteClicksMetric.vue'),
  PhoneClicksMetric: () => import('@/components/widget-library/google-business-profile/PhoneClicksMetric.vue'),
  DirectionsRequestedMetric: () => import('@/components/widget-library/google-business-profile/DirectionsRequestedMetric.vue'),
  GoogleMapsMetric: () => import('@/components/widget-library/google-business-profile/GoogleMapsMetric.vue'),
  TrendingViewsBar: () => import('@/components/widget-library/google-business-profile/TrendingViewsBar.vue'),
  AverageRatingDonut: () => import('@/components/widget-library/google-business-profile/AverageRatingDonut.vue'),
  RatingsBreakdownBar: () => import('@/components/widget-library/google-business-profile/RatingsBreakdownBar.vue'),
  ListingInfoTable: () => import('@/components/widget-library/google-business-profile/ListingInfoTable.vue'),
  // Reputation Components
  AverageRatingMetric: () => import('@/components/widget-library/reputation/AverageRatingMetric.vue'),
  FiveStarReviewsMetric: () => import('@/components/widget-library/reputation/FiveStarReviewsMetric.vue'),
  ListingReviewSummaryTable: () => import('@/components/widget-library/reputation/ListingReviewSummaryTable.vue'),
  OneStarReviewsMetric: () => import('@/components/widget-library/reputation/OneStarReviewsMetric.vue'),
  OverallReviewsBarChart: () => import('@/components/widget-library/reputation/OverallReviewsBarChart.vue'),
  RespondedToRateMetric: () => import('@/components/widget-library/reputation/RespondedToRateMetric.vue'),
  ReviewCompsTable: () => import('@/components/widget-library/reputation/ReviewCompsTable.vue'),
  ReviewsRespondedMetric: () => import('@/components/widget-library/reputation/ReviewsRespondedMetric.vue'),
  TotalReviewsMetric: () => import('@/components/widget-library/reputation/TotalReviewsMetric.vue'),
  // Other Components
  AppDividerWidget: () => import('@/components/ui/AppDividerWidget.vue'),
  // Add other types if needed
};

const componentCache = {};

export function getComponent(name) {
  if (!name) {
    console.error(`Invalid argument passed to getComponent: name=${name}`);
    return null;
  }

  if (componentCache[name]) {
    return componentCache[name]; // Return cached component if available
  }

  const loader = componentRegistry[name];

  if (!loader) {
    console.error(`Component ${name} not found in componentRegistry`);
    return null; // or throw an error
  }

  const asyncComponent = markRaw(defineAsyncComponent(loader));
  componentCache[name] = asyncComponent; // Cache the component

  return asyncComponent;
}
