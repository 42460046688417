import { previews } from '@/helpers/IconRegistry';
import { largeWidget, smallWidget } from '@/helpers/WidgetSizes';
import { v4 as uuidv4 } from 'uuid';
import { mediumWidget } from '../WidgetSizes';

const commonFields = {
  integration: 'Leasing',
  icon: null,
};

export const leasingWidgets = [
  {
    title: 'Guest Cards',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'GuestCardsMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Applications',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'ApplicationsMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Leases',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'LeasesMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Lease Rate',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'LeaseRateMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Guest Card Activity',
    type: 'Line Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppLinePreview,
    i: uuidv4(),
    component: 'GuestCardsActivityLine',
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Guest Cards Sources',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    i: uuidv4(),
    component: 'GuestCardSourcesTable',
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Shows / Guest Cards',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'ShowsGuestCardsMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Applications / Shows',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'ApplicationsShowMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Leases / Shows',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'LeasesShowsMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Agents',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    i: uuidv4(),
    component: 'AgentsTable',
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Trending Guest Cards',
    type: 'Bar Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppBarPreview,
    i: uuidv4(),
    component: 'GuestCardsBar',
    ...mediumWidget,
    ...commonFields,
  },
  {
    title: 'Trending Applications',
    type: 'Bar Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppBarPreview,
    i: uuidv4(),
    component: 'ApplicationsBar',
    ...mediumWidget,
    ...commonFields,
  },
  {
    title: 'Trending Leases',
    type: 'Bar Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppBarPreview,
    i: uuidv4(),
    component: 'LeasesBar',
    ...mediumWidget,
    ...commonFields,
  },
  {
    title: 'Leasing Funnel',
    type: 'Bar Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppBarPreview,
    i: uuidv4(),
    component: 'LeasingFunnelBar',
    ...mediumWidget,
    ...commonFields,
  },
];
