<!-- ReportsTopFilterSection.vue -->
<script setup>
// Imports
import { ref } from 'vue';
import AppPropertiesSelector from '@/components/ui/selects/AppPropertiesSelector.vue';
import AppButton from '@/components/ui/buttons/AppButton.vue';
import { reportOptionsData } from '@/data/reportsData';
import AppSingleSelector from '@/components/ui/selects/AppSingleSelector.vue';
import AppIconAngleDownPair from '@/components/svg/AppIconAngleDownPair.vue';
import { useGlobalStore } from '@/stores/GlobalStore';
import AppVisibleFilterWrapper from '@/components/ui/AppVisibleFilterWrapper.vue';
import { useReportsStore } from '@/stores/ReportsStore';

const globalStore = useGlobalStore();
const reportsStore = useReportsStore();

// A local ref for the final text, e.g. "Multiple (4)" or "12 Central Square"
const localPropertiesText = ref('');

// We'll store the user-chosen report ID in a local ref
const localSelectedReport = ref(null);

function handleClick() {
  globalStore.toggleVisibleFilter();
}

// This is triggered from AppPropertiesSelector, so we update localPropertiesText in real time
function handleSelectedPropertyText(newText) {
  localPropertiesText.value = newText;
}

// Only when the user clicks "View" do we store final text & report ID
function handleView() {
  // Copy text into the store so the breadcrumb can see it
  reportsStore.selectedPropertyText = localPropertiesText.value || '';

  // load the chosen report
  reportsStore.loadReport(localSelectedReport.value);
}

function handleReset() {
  reportsStore.resetReport();
  localPropertiesText.value = '';
  localSelectedReport.value = null;
}
</script>

<template>
  <div class="styles_topFilter__container">
    <div class="grid">
      <!-- Properties Multi-Selector -->
      <div class="flex-auto">
        <!-- We pass handleSelectedPropertyText so we get "Multiple (#)" or single name -->
        <AppPropertiesSelector @update:selectedOptionText="handleSelectedPropertyText" />
      </div>

      <!-- Reports Dropdown -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector
          title="Report"
          :options="reportOptionsData"
          v-model="localSelectedReport"
          placeholder="Select a report"
          :show-search="true"
          body-width="300px"
        />
      </AppVisibleFilterWrapper>

      <!-- filter button -->
      <div class="filter-button-container">
        <div class="filter-clear-all" v-if="reportsStore.report" @click="handleReset">Reset</div>
        <div v-else class="filter-clear-all-placeholder"></div>

        <AppButton label="View" class="filter-button-bottom" @click="handleView" />
      </div>
    </div>
    <AppButton toggle_btn @click="handleClick">
      <AppIconAngleDownPair />
    </AppButton>
  </div>
</template>

<style scoped lang="scss">
@import '../../styles/settings/mixins';

.grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.flex-auto {
  flex: 1 1 auto;
}

.filter-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-clear-all,
.filter-clear-all-placeholder {
  color: var(--secondary-color);
  background-color: transparent;
  border: none;
  font-size: 13px;
  text-decoration: underline;
  margin-bottom: 5px;
  height: 15px;
  visibility: hidden;
}

.filter-clear-all {
  visibility: visible;
  &:hover {
    color: var(--primary-text-color);
    cursor: pointer;
  }
}
</style>
