<!-- AppBreadcrumbs.vue -->
<script setup>
import AppIconArrowRight from '@/components/svg/AppIconArrowRight.vue';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  // Optional: A general click handler if you want the parent to handle logic externally
  onBreadcrumbClick: {
    type: Function,
    default: null,
  },
});

function handleClick(item, index) {
  if (item.action && typeof item.action === 'function') {
    item.action();
  } else if (props.onBreadcrumbClick) {
    props.onBreadcrumbClick(item, index);
  }
}
</script>

<template>
  <nav aria-label="Breadcrumb" class="breadcrumb-nav">
    <ol class="breadcrumb-list">
      <li v-for="(item, index) in items" :key="index" class="breadcrumb-item">
        <!-- If there's an action, make it clickable. otherwise just text -->
        <span :class="{ 'breadcrumb-clickable': item.action || onBreadcrumbClick }" @click="handleClick(item, index)">
          {{ item.text }}
        </span>

        <span v-if="index < items.length - 1" class="breadcrumb-separator"> / </span>
      </li>
    </ol>
  </nav>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.breadcrumb-nav {
  padding-bottom: 5px;
}

.breadcrumb-list {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;

  span {
    svg {
      fill: var(--secondary-color);
    }
  }
}
.breadcrumb-item {
  display: flex;
  align-items: center;
  color: var(--secondary-color);
  font-size: 14px;
  opacity: 0.6;
  transition: all 0.3s ease;

  &:last-child {
    opacity: 1;
    color: var(--primary-text-color);
    padding-right: 5px;
  }

  &:hover {
    opacity: 1;
  }
}
.breadcrumb-separator {
  margin: 0 8px;
  display: flex;
  align-items: center;
}
.breadcrumb-clickable {
  cursor: pointer;
  transition: all 0.3s ease;
}
.breadcrumb-clickable:hover {
  text-decoration: underline;
}
</style>
