<!-- ProfileGridView.vue -->
<script setup>
import { ref } from 'vue';
import AppTextInput from '@/components/ui/AppTextInput.vue';
import AppListPills from '@/components/ui/AppListPills.vue';
import AppIconUpload from '@/components/svg/AppIconUpload.vue';
import AppIconDownload from '@/components/svg/AppIconDownload.vue';
import AppIconAddAlt from '@/components/svg/AppIconAddAlt.vue';
import AppIconLock from '@/components/svg/AppIconLock.vue';
import { portfolioGroups, customGroups, smartGroups, hubspotGroups } from '@/data/settings';

// Helper function to safely filter groups if they are defined
const filterGroups = (groups, names) => {
  return groups ? groups.filter((group) => names.includes(group.name)) : [];
};

const defaultPortfolioGroups = ref([]);
const defaultCustomGroups = ref([]);
const defaultSmartGroups = ref([]);
const defaultHubspotGroups = ref([]);

// Will update this to pull from properties data
const propertyName = ref('URBN City Flats');
const address = ref('1234 Biscayne Blvd, Miami, FL 33132');
const website = ref('www.urbncitylofts.com');

// Initialize the default group selections immediately since it's static data
defaultPortfolioGroups.value = filterGroups(portfolioGroups, ['Dyverse', 'MACK Management']);
defaultCustomGroups.value = filterGroups(customGroups, ['URBN', 'Miami', 'Nonsi Nxumalo', 'Sebastian Tovar-Morales']);
defaultSmartGroups.value = filterGroups(smartGroups, ['Exposure Risk', 'Google Ads', 'Ads Manager verified']);
defaultHubspotGroups.value = filterGroups(hubspotGroups, ['Dyverse', 'URBN', 'Miami', 'Florida', 'Nonsi Nxumalo', 'Sebastian Tovar-Morales']);

// tracking locked group states
const lockedGroups = ref({
  smart: true,
  hubspot: true,
});

// toggle locked groups
const toggleGroupLock = (group) => {
  lockedGroups.value[group] = !lockedGroups.value[group];
};
</script>

<template>
  <div class="profile">
    <div class="profile-section">
      <div class="block">
        <div class="block-header">Property Information</div>
        <div class="property-info-grid">
          <div class="property-inputs">
            <!-- Property Name -->
            <AppTextInput title="Name" placeholder="Enter a name for the new property." v-model="propertyName" />
            <!-- Property Address -->
            <AppTextInput title="Address" placeholder="Address" v-model="address" />
            <!-- Property Website -->
            <AppTextInput title="Website" placeholder="Website URL" v-model="website" />
          </div>
        </div>
      </div>

      <div class="block">
        <div class="block-header">Property Image</div>
        <div class="property-image-section">
          <div class="property-image">
            <!-- Property Image Preview -->
            <img src="@/assets/images/property-preview.png" class="image-preview" alt="URBN Lofts Apartments" />
          </div>
          <div class="upload-box">
            <div class="upload-instructions">
              <AppIconUpload />
              <div class="upload-text"><span>Click to upload</span> or drag and drop</div>
              <div class="upload-text">PNG or JPG (max. 800x400px)</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="block groups-section">
      <div class="block-header">
        Groups
        <div class="header-controls">
          <span>
            <AppIconDownload />
            Template
          </span>
          <span>
            <AppIconAddAlt />
            Import
          </span>
        </div>
      </div>
      <div class="groups-grid">
        <div class="group">
          <div class="group-label">Portfolios</div>
          <AppListPills :listItems="portfolioGroups" v-model="defaultPortfolioGroups" class="pill-blue" />
        </div>
        <div class="group">
          <div class="group-label">Custom</div>
          <AppListPills :listItems="customGroups" v-model="defaultCustomGroups" class="pill-purple" />
        </div>
        <div class="group" :class="{ 'group-locked': lockedGroups.smart }">
          <div class="group-label">
            Smart
            <AppIconLock @click="toggleGroupLock('smart')" />
          </div>
          <AppListPills
            :listItems="smartGroups"
            v-model="defaultSmartGroups"
            class="pill-orange"
            :disabled="lockedGroups.smart"
            :class="{ unlocked: !lockedGroups.smart }"
          />
        </div>
        <div class="group" :class="{ 'group-locked': lockedGroups.hubspot }">
          <div class="group-label">
            Hubspot
            <AppIconLock @click="toggleGroupLock('hubspot')" />
          </div>
          <AppListPills
            :listItems="hubspotGroups"
            v-model="defaultHubspotGroups"
            class="pill-green"
            :disabled="lockedGroups.hubspot"
            :class="{ unlocked: !lockedGroups.hubspot }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.profile {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;

  .block {
    color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    border: 1px solid var(--secondary-color);
    background-color: var(--property-card-bg);
    transition: all 0.3s ease;
    width: 100%;
  }

  .profile-section {
    display: flex;
    gap: 25px;

    .property-info-grid {
      padding: 15px 20px 25px;
    }

    .property-inputs {
      :deep(.text-input-container) {
        padding: 10px 0;
      }
    }
  }

  .block-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--secondary-color);
    padding: 18px;
    gap: 10px;
    flex-shrink: 0;

    .header-controls {
      display: flex;
      align-items: center;
      gap: 15px;

      span {
        display: flex;
        align-items: center;

        &:last-child {
          gap: 4px;
        }
      }

      svg {
        height: 18px;

        &:deep(.colorize) {
          fill: var(--primary-text-color);
        }
      }
    }
  }

  .property-image-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    flex-grow: 1;

    .property-image,
    .upload-box {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .property-image {
      .image-preview {
        max-width: 200px;
        height: auto;
      }
    }

    .upload-box {
      .upload-instructions {
        text-align: center;
        color: var(--secondary-color);
        border-radius: 7.35px;
        border: 0.735px dashed #617086;
        background: rgba(217, 217, 217, 0.05);
        padding: 20px;

        .upload-text {
          text-align: center;
          font-size: 16px;
          font-weight: 300;

          span {
            font-weight: 600;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }

  .groups-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;

    .group {
      .group-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--secondary-color);
        font-family: Lato;
        font-size: 14.359px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0;
      }

      .pill-blue {
        :deep(.pill) {
          border-radius: 20.3px;
          border: 1px solid #0e779e;
          background: rgba(14, 119, 158, 0.25);
        }
      }

      .pill-purple {
        :deep(.pill) {
          border-radius: 20.3px;
          border: 1px solid #7133a5;
          background: rgba(113, 51, 165, 0.25);
        }
      }

      .pill-orange {
        :deep(.pill) {
          border-radius: 20.3px;
          border: 1px solid #fa7921;
          background: rgba(250, 121, 33, 0.25);
        }
      }

      .pill-green {
        :deep(.pill) {
          border-radius: 20.3px;
          border: 1px solid #00b43c;
          background: rgba(0, 180, 60, 0.25);
        }
      }
    }
  }
}
</style>
