<!-- IntegrationsGridView.vue -->
<script setup>
import { reactive, computed } from 'vue';
import { useSettingsStore } from '@/stores/SettingsStore'; // Import the store
import { useModalStore } from '@/stores/ModalStore';
import SyncedPill from '@/assets/images/synced-pill.png';
import ErrorPill from '@/assets/images/error-status-pill.png';
import NotConfiguredPill from '@/assets/images/not-configured-pill.png';
import AppIconCopy from '@/components/svg/AppIconCopy.vue';
import AppTooltip from '@/components/ui/AppTooltip.vue';

const modalStore = useModalStore();
const settingsStore = useSettingsStore();

// Getting integrations from settings store
const integrations = computed(() => settingsStore.allIntegrations);

function handleCardClick(integration) {
  const modalId = integration.integration_sync_status ? `synced${integration.integration_name.replace(/\s/g, '')}Modal` : 'settingsNotSyncedModal';
  modalStore.openModal(modalId, integration);
}

// Tooltip text state for better UX
const tooltipTexts = reactive(
  integrations.value.reduce((acc, integration) => {
    acc[integration.id] = 'Copy';
    return acc;
  }, {})
);

// Copy text to clipboard function and update it's text
function copyToClipboard(text, integrationId) {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      tooltipTexts[integrationId] = 'Copied!';
      setTimeout(() => {
        tooltipTexts[integrationId] = 'Copy';
      }, 2000);
    })
    .catch((err) => {
      console.error('Failed to copy: ', err);
    });
}
</script>

<template>
  <div class="integration-grid">
    <div class="integration-block" v-for="integration in integrations" :key="integration.id" @click="handleCardClick(integration)">
      <div class="integration-card">
        <div class="integration-card__header">
          <div class="integration-icon">
            <component :is="integration.integration_icon" />
          </div>
          <h3 class="integration-name">
            {{ integration.integration_name }}
            <span class="last-edit">Last edit {{ integration.last_edited }}</span>
          </h3>
        </div>
        <div class="integration-card__body">
          <p class="integration-description">{{ integration.integration_description }}</p>
        </div>
        <div class="integration-card__footer">
          <span
            class="status"
            :class="{ synced: integration.integration_sync_status === true, error: integration.integration_sync_status === 'error' }"
          >
            <AppTooltip>
              <img
                :src="
                  integration.integration_sync_status === true
                    ? SyncedPill
                    : integration.integration_sync_status === 'error'
                    ? ErrorPill
                    : NotConfiguredPill
                "
                class="status-pill"
                :alt="
                  integration.integration_sync_status === true
                    ? 'Synced'
                    : integration.integration_sync_status === 'error'
                    ? 'Error'
                    : 'Not Configured'
                "
              />
              <template #text>
                <span class="tooltip-description">
                  {{
                    integration.integration_sync_status === true
                      ? 'Integration successfully synced'
                      : integration.integration_sync_status === 'error'
                      ? 'There was an error with the integration'
                      : 'Integration not configured'
                  }}
                </span>
              </template>
            </AppTooltip>
            {{
              integration.integration_sync_status === true ? 'Synced' : integration.integration_sync_status === 'error' ? 'Error' : 'Not Configured'
            }}
          </span>
          <span v-if="integration.tag_id" class="tag-id">
            <button class="copy-icon" @click.stop="copyToClipboard(integration.tag_id, integration.id)">
              <AppTooltip>
                <AppIconCopy />
                <template #text>
                  <span class="tooltip-description">{{ tooltipTexts[integration.id] }}</span>
                </template>
              </AppTooltip>
            </button>
            {{ integration.tag_id }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/settings/_mixins.scss';

.integration-grid {
  display: inline-grid;
  gap: 15px;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(335px, 1fr));
}

.integration-block {
  border-radius: 24px;
}

.integration-card {
  color: #fff;
  height: 258px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  border: 1px solid var(--secondary-color);
  background-color: var(--property-card-bg);
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    border-color: var(--primary-color);
  }
}

.integration-card__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding: 20px 20px 0;
}

.integration-card__body {
  flex-grow: 1;
  padding: 20px;
}

.integration-icon {
  background-color: var(--element-bg);
  width: 70px;
  height: 70px;
  border-radius: 50%;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    padding: 16px;
  }
}

.integration-name {
  font-family: Lato, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--primary-text-color);
}

.last-edit {
  display: block;
  font-family: Lato, serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  color: var(--secondary-color);
  font-style: italic;
}

.integration-description {
  color: var(--primary-text-color);
  font-family: Lato, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.status {
  color: var(--secondary-color);
  font-family: Lato, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 8px;

  &.synced {
    color: var(--secondary-color);
  }

  .status-pill {
    width: 52px;
    height: 28px;
  }
}

.copy-icon {
  background: transparent;
  border: none;
  margin-right: 5px;

  svg.icon-color {
    fill: var(--primary-text-color);
  }
}

.tag-id {
  display: flex;
  color: var(--primary-text-color);
  text-align: center;
  font-family: Lato, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.925px;
  /* 191.045% */
  letter-spacing: -0.228px;
}

.integration-card__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #121e2a;
  border-top: 1px solid var(--border-color);
  padding: 10px 20px;
  border-radius: 0 0 20px 20px;
  height: 60px;
}
</style>
