<!-- ReportsPageHeader.vue -->
<script setup>
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';

import AppIconDotsVertical from '@/components/svg/AppIconDotsVertical.vue';
import AppIconBoxWrapper from '@/components/wrappers/AppIconBoxWrapper.vue';
import AppOverlayMenu from '@/components/ui/AppOverlayMenu.vue';
import AppIconQuestion from '@/components/svg/AppIconQuestion.vue';
import AppTooltip from '@/components/ui/AppTooltip.vue';

import { useReportsStore } from '@/stores/ReportsStore';
import { useModalStore } from '@/stores/ModalStore';
import AppBreadcrumbs from '@/components/ui/AppBreadcrumbs.vue';

const reportsStore = useReportsStore();
const modalStore = useModalStore();

// Overlay logic
const isOverlayVisible = ref(false);
const target = ref(null);

function toggleOverlay() {
  isOverlayVisible.value = !isOverlayVisible.value;
}

onClickOutside(target, () => {
  if (isOverlayVisible.value) {
    isOverlayVisible.value = false;
  }
});

function handleOptionClicked(option) {
  console.log(`Option ${option.text} clicked`);
}

// If user clicks "Edit settings" in the overlay
function handleOpenEditReportModal(report) {
  if (report === 1) {
    modalStore.openModal('EditBenchmarkReportModal');
  } else if (report === 6) {
    modalStore.openModal('EditTrendingReportModal');
  } else {
    console.error('Invalid report type');
  }
}

// Reset entire state on first crumb click
function goToReportsRoot() {
  reportsStore.resetReport();
}

// Build breadcrumb items
const breadcrumbItems = computed(() => {
  const items = [
    {
      text: 'Reset',
      action: goToReportsRoot,
    },
  ];

  // If there's a selected property text, show it
  if (reportsStore.selectedPropertyText) {
    items.push({ text: reportsStore.selectedPropertyText });
  }

  // If there's a report name
  if (reportsStore.reportName) {
    items.push({ text: reportsStore.reportName });
  }

  return items;
});
</script>

<template>
  <!-- If a report is loaded, show dynamic header with breadcrumbs -->
  <div class="styles_pageHeader__section">
    <div class="styles_pageHeader__container">
      <!-- Left side of page header -->
      <div class="left-side-container">
        <div>
          <transition name="fade-breadcrumbs">
            <AppBreadcrumbs v-if="reportsStore.report" :items="breadcrumbItems" />
          </transition>
          <h3 class="page-title">
            Reports
            <AppTooltip>
              <AppIconQuestion class="question-icon" />
              <template #text>
                <span class="description"> Default report settings: 30 day, GA4 & Google Ads Search selected if applicable. </span>
              </template>
            </AppTooltip>
          </h3>
        </div>
      </div>

      <!-- Right side of page header -->
      <div class="styles_pageHeader__icon-container">
        <div ref="target">
          <AppIconBoxWrapper @click="toggleOverlay">
            <AppIconDotsVertical />
          </AppIconBoxWrapper>

          <AppOverlayMenu
            v-if="isOverlayVisible"
            @option-clicked="handleOptionClicked"
            :options="['edit-settings', 'make-recurring', 'export-to-csv', 'export-to-pdf', 'share-link']"
            bodyWidth="150px"
          >
            <template #edit-settings>
              <div @click="handleOpenEditReportModal(reportsStore.selectedReport)">Edit settings</div>
            </template>
            <template #make-recurring>
              <div>Make recurring</div>
            </template>
            <template #export-to-csv>
              <div>Export to CSV</div>
            </template>
            <template #export-to-pdf>
              <div>Export to PDF</div>
            </template>
            <template #share-link>
              <div>Share link</div>
            </template>
          </AppOverlayMenu>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';
@import 'src/styles/settings/_themes.scss';

/* Transition classes for fade */
.fade-breadcrumbs-enter-active,
.fade-breadcrumbs-leave-active {
  transition: opacity 0.3s ease;
}

.fade-breadcrumbs-enter-from,
.fade-breadcrumbs-leave-to {
  opacity: 0;
}

.question-icon {
  height: 14px;
  width: 14px;
  position: relative;
  top: 1px;
}

.pill {
  border: 1px solid var(--secondary-color);
  background-color: var(--secondary-color);
  border-radius: 50px;
  margin: 0 10px;
  padding: 5px 13px;
  font-size: 0.65em;
  color: var(--primary-text-color);
}

.left-side-container {
  display: flex;
  align-items: center;
  height: 34px;
}

.styles_pageHeader__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styles_pageHeader__icon-container {
  display: flex;
  gap: 10px;
}
</style>
