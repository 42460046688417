<!-- AppIconUpload.vue -->
<script setup></script>

<template>
  <svg class="icon-color" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
    <rect x="0.803711" y="0.566223" width="25" height="25" rx="12.5" fill="#617086" fill-opacity="0.5" />
    <path
      class="colorize"
      d="M17.2162 10.657C17.0793 9.84349 16.6765 9.10106 16.0605 8.53765C15.3759 7.91105 14.4861 7.56616 13.5593 7.56616C12.8432 7.56616 12.1456 7.77152 11.5479 8.15853C11.0503 8.47973 10.637 8.91413 10.3448 9.42488C10.2184 9.40119 10.0867 9.38802 9.95511 9.38802C8.83619 9.38802 7.92526 10.299 7.92526 11.4179C7.92526 11.5627 7.94106 11.7022 7.96739 11.8391C7.24338 12.3657 6.80371 13.2134 6.80371 14.1164C6.80371 14.8457 7.07488 15.5539 7.56984 16.1147C8.07796 16.6886 8.74931 17.0283 9.46542 17.0678C9.47332 17.0678 9.47858 17.0678 9.48648 17.0678H11.7506C11.9481 17.0678 12.1061 16.9098 12.1061 16.7123C12.1061 16.5149 11.9481 16.3569 11.7506 16.3569H9.49701C8.42022 16.2911 7.51455 15.267 7.51455 14.1138C7.51455 13.3687 7.91473 12.6737 8.55975 12.2972C8.70982 12.2103 8.77301 12.0287 8.71509 11.8654C8.66243 11.7233 8.6361 11.5732 8.6361 11.4126C8.6361 10.686 9.22847 10.0936 9.95511 10.0936C10.1104 10.0936 10.2631 10.1199 10.4053 10.1726C10.5791 10.2358 10.7713 10.1568 10.8502 9.99092C11.3426 8.94572 12.4062 8.27174 13.562 8.27174C15.1153 8.27174 16.3974 9.43541 16.5449 10.9782C16.5607 11.1388 16.6818 11.2678 16.8397 11.2941C18.0113 11.4942 18.8959 12.5763 18.8959 13.811C18.8959 15.1195 17.8665 16.2569 16.5975 16.3543H14.6572C14.4597 16.3543 14.3018 16.5122 14.3018 16.7097C14.3018 16.9072 14.4597 17.0651 14.6572 17.0651H16.6107C16.6186 17.0651 16.6265 17.0651 16.637 17.0651C17.44 17.0072 18.1903 16.6386 18.7485 16.0226C19.304 15.4118 19.6068 14.6272 19.6068 13.811C19.6041 12.3341 18.5958 11.0203 17.2162 10.657Z"
      fill="white"
      fill-opacity="0.5"
    />
    <path
      class="colorize"
      d="M15.3399 14.4535C15.4795 14.3139 15.4795 14.0902 15.3399 13.9506L13.4575 12.0682C13.3917 12.0024 13.2995 11.9629 13.2074 11.9629C13.1152 11.9629 13.0231 11.9997 12.9573 12.0682L11.0749 13.9506C10.9353 14.0902 10.9353 14.3139 11.0749 14.4535C11.1433 14.5219 11.2355 14.5588 11.325 14.5588C11.4145 14.5588 11.5066 14.5246 11.5751 14.4535L12.852 13.1766V19.045C12.852 19.2424 13.0099 19.4004 13.2074 19.4004C13.4048 19.4004 13.5628 19.2424 13.5628 19.045V13.1766L14.8397 14.4535C14.9766 14.593 15.2004 14.593 15.3399 14.4535Z"
      fill="white"
      fill-opacity="0.5"
    />
  </svg>
</template>

<style lang="scss" scoped>
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}
</style>
