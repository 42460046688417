<!-- AppIconKaiSearch.vue -->
<template>
  <svg width="30" height="65" viewBox="0 0 226 226" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <path
        id="Ellipse 204"
        d="M127.751 111.179C127.751 122.318 118.72 131.349 107.581 131.349C96.4414 131.349 87.4111 122.318 87.4111 111.179C87.4111 100.04 96.4414 91.0093 107.581 91.0093C118.72 91.0093 127.751 100.04 127.751 111.179Z"
        fill="url(#paint0_linear_433_2499)"
      />
      <g id="Union" filter="url(#filter0_d_433_2499)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M111.795 59.8585C112.907 60.7451 113.684 61.9828 114 63.369L119.217 86.3037C120.003 89.7633 121.752 92.9301 124.261 95.4389C126.77 97.9476 129.936 99.6963 133.396 100.483L156.328 105.7C157.714 106.016 158.951 106.793 159.838 107.905C160.725 109.016 161.207 110.395 161.207 111.817C161.207 113.239 160.725 114.618 159.838 115.73C158.951 116.841 157.714 117.618 156.328 117.934L133.396 123.151C129.936 123.938 126.77 125.687 124.261 128.195C121.752 130.704 120.003 133.871 119.217 137.331L114 160.262C113.684 161.648 112.907 162.886 111.795 163.773C110.684 164.659 109.304 165.142 107.883 165.142C106.461 165.142 105.081 164.659 103.97 163.773C102.859 162.886 102.081 161.648 101.765 160.262L96.5485 137.331C95.7617 133.871 94.0131 130.704 91.5043 128.195C88.9955 125.687 85.8287 123.938 82.3692 123.151L59.4375 117.934C58.0514 117.618 56.8136 116.841 55.9271 115.73C55.0405 114.618 54.5576 113.239 54.5576 111.817C54.5576 110.395 55.0405 109.016 55.9271 107.905C56.8136 106.793 58.0514 106.016 59.4375 105.7L82.3692 100.483C85.8287 99.6963 88.9955 97.9476 91.5043 95.4389C94.0131 92.9301 95.7617 89.7633 96.5485 86.3037L101.765 63.369C102.081 61.9828 102.859 60.7451 103.97 59.8585C105.081 58.9719 106.461 58.4891 107.883 58.4891C109.304 58.4891 110.684 58.9719 111.795 59.8585ZM107.887 131.346C107.884 131.347 107.881 131.347 107.878 131.347C107.879 131.351 107.881 131.356 107.883 131.361C107.884 131.356 107.886 131.351 107.887 131.346Z"
          fill="url(#paint1_linear_433_2499)"
        />
      </g>
      <g id="Vector" filter="url(#filter1_d_433_2499)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M172.068 47.4646C136.569 11.9692 79.0205 11.9692 43.5219 47.4646C8.02654 82.9631 8.02654 140.512 43.5219 176.01C77.5272 210.016 131.773 211.443 167.478 180.295L194.413 207.227C194.991 207.826 195.684 208.304 196.449 208.633C197.215 208.962 198.038 209.135 198.871 209.142C199.704 209.149 200.53 208.99 201.301 208.675C202.072 208.359 202.773 207.894 203.362 207.304C203.951 206.715 204.417 206.015 204.732 205.244C205.048 204.473 205.206 203.647 205.199 202.814C205.192 201.98 205.019 201.157 204.69 200.392C204.361 199.626 203.883 198.934 203.284 198.355L176.356 171.421C207.503 135.715 206.073 81.4699 172.068 47.4646ZM52.3966 56.3392C82.9919 25.7438 132.598 25.7438 163.196 56.3392C193.788 86.9345 193.788 136.54 163.196 167.139C132.604 197.737 82.9919 197.731 52.3966 167.139C21.8012 136.54 21.8012 86.9345 52.3966 56.3392Z"
          fill="url(#paint2_linear_433_2499)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_433_2499"
        x="-10.0149"
        y="4.67863"
        width="235.795"
        height="235.798"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="10.7621" />
        <feGaussianBlur stdDeviation="32.2863" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 1 0 0 0 0 0.0392157 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_433_2499" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_433_2499" result="shape" />
      </filter>
      <filter
        id="filter1_d_433_2499"
        x="-26.148"
        y="-11.4433"
        width="274.396"
        height="274.396"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="10.7621" />
        <feGaussianBlur stdDeviation="21.5242" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 1 0 0 0 0 0.0392157 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_433_2499" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_433_2499" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_433_2499" x1="136.155" y1="134.71" x2="77.3263" y2="82.6052" gradientUnits="userSpaceOnUse">
        <stop stop-color="#00FF0A" />
        <stop offset="1" stop-color="#125026" />
      </linearGradient>
      <linearGradient id="paint1_linear_433_2499" x1="154" y1="165" x2="74.5" y2="58" gradientUnits="userSpaceOnUse">
        <stop stop-color="#00FF0A" />
        <stop offset="1" stop-color="#125026" />
      </linearGradient>
      <linearGradient id="paint2_linear_433_2499" x1="-57.4918" y1="-1.85059" x2="329.706" y2="336.804" gradientUnits="userSpaceOnUse">
        <stop offset="0.144204" stop-color="#00FF0A" />
        <stop offset="0.688062" stop-color="#182838" />
        <stop offset="1" stop-color="#125026" />
      </linearGradient>
    </defs>
  </svg>
</template>

<style scoped lang="scss">
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}
</style>
