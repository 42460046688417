<!-- PermissionsGridView.vue -->
<script setup>
// Imports...
import { onMounted, ref } from 'vue';
import TableLayout from '@/components/settings/TableLayout.vue';
import AppToggleButton from '@/components/ui/buttons/AppToggleButton.vue';
import AppSingleSelector from '@/components/ui/selects/AppSingleSelector.vue';
import AppIconAddAlt from '@/components/svg/AppIconAddAlt.vue';
import AppSpinner from '@/components/ui/AppSpinner.vue';
import AppOverlayMenu from '@/components/ui/AppOverlayMenu.vue';
import { useUsersStore } from '@/stores/UsersStore';
import { kyzenSubscription, reportsPermissions, permissionTools } from '@/data/settings.js';

const usersStore = useUsersStore();
const selectedSubscription = ref('');
const isOverlayVisible = ref(false);
const overlayPosition = ref({ x: 0, y: 0 });
const selectedRow = ref(null);

onMounted(async () => {
  await usersStore.fetchUsers();
});

function handleRowClick(event, row) {
  console.log('Row clicked: ', row);
  // Setting the position of the row overlays to the mouse click position
  const { clientX, clientY } = event;

  overlayPosition.value = {
    x: clientX,
    y: clientY,
  };
  selectedRow.value = row;
  isOverlayVisible.value = true;
}

function handleOptionClicked(option) {
  console.log(`Option ${option} clicked for row: `, selectedRow.value);
  isOverlayVisible.value = false;
}
</script>

<template>
  <!-- Users Table -->
  <div v-if="!usersStore.initialLoad" class="users-table">
    <TableLayout :showAddUserButton="true" :onRowClick="handleRowClick">
      <template #table-header>
        <div class="table-title">Users</div>
        <button class="add-user-button">
          <AppIconAddAlt color="" />
          Add Users
        </button>
      </template>
      <template #row="{ row }">
        <div class="row-container" @click="handleRowClick($event, row)">
          <div class="cell" v-for="(value, key) in row" :key="key">
            {{ value }}
          </div>
        </div>
      </template>
    </TableLayout>
  </div>
  <div v-else class="center-spinner">
    <AppSpinner />
  </div>
  <!-- Row Overlay Menu -->
  <AppOverlayMenu
    v-if="isOverlayVisible"
    :x="overlayPosition.x"
    :y="overlayPosition.y"
    bodyWidth="160px"
    :options="['edit-user', 'delete-user', 'share-user', 'copy-user']"
    @option-clicked="handleOptionClicked"
  >
    <template #edit-user>
      <div>Edit</div>
    </template>
    <template #delete-user>
      <div>Delete</div>
    </template>
    <template #share-user>
      <div>Share</div>
    </template>
    <template #copy-user>
      <div>Copy</div>
    </template>
  </AppOverlayMenu>

  <!-- Pipeline Settings -->
  <div class="permissions-grid">
    <div class="permission-card pipeline">
      <div class="permission-card__header">
        <h3 class="permission-name">Pipeline</h3>
      </div>
      <div class="permission-card__body">
        <!-- Pipeline Selector -->
        <AppSingleSelector
          body-width="100%"
          title="Kyzen Subscription"
          placeholder="Select a pipeline"
          :options="kyzenSubscription"
          v-model="selectedSubscription"
        />
      </div>
    </div>

    <!-- Report Settings -->
    <div class="permission-card reports">
      <div class="permission-card__header">
        <h3 class="permission-name">Reports</h3>
      </div>
      <div class="permission-card__body">
        <div v-for="report in reportsPermissions" v-bind:key="report.name">
          <AppToggleButton :id="report.name" label="" />
          {{ report.name }}
        </div>
      </div>
    </div>

    <!-- Tools -->
    <div class="permission-card tools">
      <div class="permission-card__header">
        <h3 class="permission-name">Tools</h3>
      </div>
      <div class="permission-card__body">
        <div v-for="tool in permissionTools" v-bind:key="tool.name">
          <AppToggleButton :id="tool.name" label="" />
          {{ tool.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.center-spinner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

.permissions-grid {
  display: inline-grid;
  gap: 15px;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(335px, 1fr));
  margin-top: 30px;
}

:deep(.wrapper) {
  background: var(--property-card-bg);
}

.permission-card {
  color: #fff;
  height: 258px;
  border-radius: 20px;
  border: 1px solid var(--secondary-color);
  background-color: var(--property-card-bg);
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    border-color: var(--primary-color);
  }

  &.pipeline {
    .permission-card__body {
      :deep(.app-single-selector-container) {
        display: block;
      }

      :deep(.app-single-selector-body) {
        left: 205px;
      }
    }
  }
}

.permission-card__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding: 18px;
  border-bottom: 1px solid var(--secondary-color);
}

.permission-card__body {
  flex-grow: 1;
  padding: 20px;
  width: 100%;

  div {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 4px 0;
  }
}

.permission-icon {
  background-color: var(--element-bg);
  width: 70px;
  height: 70px;
  border-radius: 50%;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    padding: 16px;
  }
}

.permission-name {
  font-family: Lato, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--primary-text-color);
}

.table-title {
  color: var(--primary-text-color);
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.users-table {
  .row-container {
    .cell {
      &:last-child {
        --cell-base-size: 167px;
      }
    }
  }
}

.add-user-button {
  background-color: transparent;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  color: var(--primary-text-color);
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  :deep(.icon-color) {
    fill: var(--primary-text-color);

    &:hover {
      fill: var(--primary-color);
    }
  }

  svg {
    width: 12px;
    height: 12px;
  }
}

:deep(.overlay-menu) {
  position: fixed;
  z-index: 10000;
  transform: translateY(10px);
}

:deep(.overlay-menu) {
  top: 0;
  left: 0;
  transform: translate(calc(var(--overlay-x, 0) * 1px), calc(var(--overlay-y, 0) * 1px));
}
</style>
