<!--KaiModal.vue-->
<script setup>
import { ref, reactive, watch, onMounted, onUnmounted, nextTick } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { KaiWebSocketService } from '@/services/kaiWebSocket';

const props = defineProps({
  initialMessage: {
    type: Object,
    default: null,
  },
});

const messages = reactive([]);
const chatInput = ref('');
const isLoading = ref(false);
const wsService = ref(null);
const connectionStatus = ref('disconnected');
const messagesContainer = ref(null);

const scrollToBottom = () => {
  if (messagesContainer.value) {
    messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
  }
};

// Watch messages array for changes
watch(
  messages,
  () => {
    nextTick(() => {
      scrollToBottom();
    });
  },
  { deep: true }
);

watch(
  () => props.initialMessage,
  (newMessage) => {
    if (newMessage && !messages.some((msg) => msg.id === newMessage.id)) {
      messages.push(newMessage);
    }
  },
  { immediate: true }
);

onMounted(async () => {
  wsService.value = new KaiWebSocketService();

  wsService.value.onMessage((data) => {
    console.log('Received WebSocket data:', data);

    if (data.status) {
      console.log('Status update:', data.status);
      return;
    }

    if (data.response !== undefined) {
      const lastMessage = messages[messages.length - 1];

      // Skip the empty response from ollama when done is true
      if (data.done && !data.response.trim()) {
        isLoading.value = false;
        return;
      }

      if (lastMessage && lastMessage.sender === 'bot' && !data.done) {
        lastMessage.text += data.response;
      } else if (data.response.trim()) {
        // Only create new message if response has content
        messages.push({
          id: uuidv4(),
          text: data.response,
          sender: 'bot',
        });
      }

      if (data.done) {
        isLoading.value = false;
      }
    }
  });

  wsService.value.onStatus((status) => {
    connectionStatus.value = status;
  });

  await wsService.value.connect();

  if (props.initialMessage?.text) {
    wsService.value.sendMessage(props.initialMessage.text);
  }

  if (props.initialMessage && !messages.some((msg) => msg.id === props.initialMessage.id)) {
    messages.push(props.initialMessage);
  }

  const observer = new MutationObserver(scrollToBottom);
  if (messagesContainer.value) {
    observer.observe(messagesContainer.value, {
      childList: true,
      subtree: true,
      characterData: true,
    });
  }

  onUnmounted(() => {
    observer.disconnect();
  });
});

onUnmounted(() => {
  if (wsService.value) {
    wsService.value.disconnect();
    wsService.value = null;
  }
});

const sendChatMessage = () => {
  const trimmedInput = chatInput.value.trim();
  if (trimmedInput !== '') {
    const userMessage = {
      id: uuidv4(),
      text: trimmedInput,
      sender: 'user',
    };

    messages.push(userMessage);

    if (wsService.value.sendMessage(trimmedInput)) {
      chatInput.value = '';
      isLoading.value = true;
    } else {
      messages.push({
        id: uuidv4(),
        text: 'Error: Not connected to server',
        sender: 'bot',
      });
    }
  }
};
</script>

<template>
  <div class="modal-content">
    <div ref="messagesContainer" class="messages-container">
      <div v-for="message in messages" :key="message.id" :class="['message', message.sender]">
        {{ message.text }}
        <span v-if="message.origin" class="origin">({{ message.origin }})</span>
      </div>
    </div>
    <div class="chat-input-container">
      <input type="text" v-model="chatInput" @keyup.enter="sendChatMessage" placeholder="Type your message..." class="chat-input" />
      <button @click="sendChatMessage" class="send-button">Send</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 100%;
}

.messages-container {
  flex: 1;
  max-height: 400px;
  overflow-y: auto;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.message {
  padding: 8px;
  margin-bottom: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-break: break-word;
  max-width: 80%;

  &.user {
    background-color: var(--primary-color, lightblue);
    align-self: flex-end;
    margin-left: auto;
    color: white;
  }

  &.bot {
    background-color: var(--secondary-color, lightgrey);
    align-self: flex-start;
    margin-right: auto;
  }
}

.chat-input-container {
  display: flex;
  gap: 8px;
  padding: 16px;
  background: none;
}

.chat-input {
  flex: 1;
  padding: 8px 12px;
  border: var(--border-size) solid var(--primary-color);
  border-radius: 12px;
  background: none;
  font-size: 14px;
  color: white;

  &:focus {
    outline: none;
    border-color: var(--primary-color, lightblue);
  }
}

.send-button {
  padding: 8px 16px;
  background-color: var(--primary-color, #007bff);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    transform: translateY(1px);
  }
}

.origin {
  font-size: 0.8em;
  opacity: 0.7;
  margin-left: 8px;
}
</style>
