<!-- AppIconKaiSearch.vue -->
<template>
  <svg width="30" height="65" viewBox="0 0 226 226" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <g id="Vector" filter="url(#filter0_d_429_3698)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M113.999 63.369C113.683 61.9828 112.906 60.7451 111.795 59.8585C110.683 58.9719 109.304 58.4891 107.882 58.4891C106.46 58.4891 105.081 58.9719 103.969 59.8585C102.858 60.7451 102.081 61.9828 101.765 63.369L96.548 86.3037C95.7612 89.7633 94.0126 92.9301 91.5038 95.4389C88.995 97.9476 85.8283 99.6963 82.3687 100.483L59.437 105.7C58.0509 106.016 56.8131 106.793 55.9266 107.905C55.04 109.016 54.5571 110.395 54.5571 111.817C54.5571 113.239 55.04 114.618 55.9266 115.73C56.8131 116.841 58.0509 117.618 59.437 117.934L82.3687 123.151C85.8283 123.938 88.995 125.687 91.5038 128.195C94.0126 130.704 95.7612 133.871 96.548 137.331L101.765 160.262C102.081 161.648 102.858 162.886 103.969 163.773C105.081 164.659 106.46 165.142 107.882 165.142C109.304 165.142 110.683 164.659 111.795 163.773C112.906 162.886 113.683 161.648 113.999 160.262L119.216 137.331C120.003 133.871 121.752 130.704 124.26 128.195C126.769 125.687 129.936 123.938 133.395 123.151L156.327 117.934C157.713 117.618 158.951 116.841 159.838 115.73C160.724 114.618 161.207 113.239 161.207 111.817C161.207 110.395 160.724 109.016 159.838 107.905C158.951 106.793 157.713 106.016 156.327 105.7L133.395 100.483C129.936 99.6963 126.769 97.9476 124.26 95.4389C121.752 92.9301 120.003 89.7633 119.216 86.3037L113.999 63.369ZM88.3384 111.817C97.5205 108.68 104.745 101.456 107.882 92.2735C111.019 101.456 118.244 108.68 127.426 111.817C122.892 113.366 118.774 115.933 115.386 119.321C111.998 122.709 109.431 126.827 107.882 131.361C106.333 126.827 103.766 122.709 100.378 119.321C96.9904 115.933 92.8719 113.366 88.3384 111.817Z"
          fill="url(#paint0_linear_429_3698)"
        />
      </g>
      <g id="Vector_2" filter="url(#filter1_d_429_3698)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M172.068 47.4646C136.569 11.9692 79.0205 11.9692 43.5219 47.4646C8.02654 82.9631 8.02654 140.512 43.5219 176.01C77.5272 210.016 131.773 211.443 167.478 180.295L194.413 207.227C194.991 207.826 195.684 208.304 196.449 208.633C197.215 208.962 198.038 209.135 198.871 209.142C199.704 209.149 200.53 208.99 201.301 208.675C202.072 208.359 202.773 207.894 203.362 207.304C203.951 206.715 204.417 206.015 204.732 205.244C205.048 204.473 205.206 203.647 205.199 202.814C205.192 201.98 205.019 201.157 204.69 200.392C204.361 199.626 203.883 198.934 203.284 198.355L176.356 171.421C207.503 135.715 206.073 81.4699 172.068 47.4646ZM52.3966 56.3392C82.9919 25.7438 132.598 25.7438 163.196 56.3392C193.788 86.9345 193.788 136.54 163.196 167.139C132.604 197.737 82.9919 197.731 52.3966 167.139C21.8012 136.54 21.8012 86.9345 52.3966 56.3392Z"
          fill="url(#paint1_linear_429_3698)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_429_3698"
        x="-10.0154"
        y="4.67863"
        width="235.795"
        height="235.798"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="10.7621" />
        <feGaussianBlur stdDeviation="32.2863" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 1 0 0 0 0 0.0392157 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_429_3698" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_429_3698" result="shape" />
      </filter>
      <filter
        id="filter1_d_429_3698"
        x="-26.148"
        y="-11.4433"
        width="274.396"
        height="274.396"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="10.7621" />
        <feGaussianBlur stdDeviation="21.5242" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 1 0 0 0 0 0.0392157 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_429_3698" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_429_3698" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_429_3698" x1="149.059" y1="126.049" x2="69.7888" y2="76.8794" gradientUnits="userSpaceOnUse">
        <stop stop-color="#00FF0A" />
        <stop offset="1" stop-color="#125026" />
      </linearGradient>
      <linearGradient id="paint1_linear_429_3698" x1="-57.4918" y1="-1.85059" x2="329.706" y2="336.804" gradientUnits="userSpaceOnUse">
        <stop offset="0.144204" stop-color="#00FF0A" />
        <stop offset="0.688062" stop-color="#182838" />
        <stop offset="1" stop-color="#125026" />
      </linearGradient>
    </defs>
  </svg>
</template>

<style scoped lang="scss">
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}
</style>
