export const orderByOptionsData = [
  {
    label: 'Last sign-in',
    value: 'Last sign-in',
  },
  {
    label: 'Email',
    value: 'Email',
  },
  {
    label: 'Recently added',
    value: 'Recent',
  },
];

export const clientOptionsData = [
  {
    label: 'Avenue5',
    value: 'Avenue5',
  },
  {
    label: 'Dweck',
    value: 'Dweck',
  },
  {
    label: 'Wood',
    value: 'Wood',
  },
  {
    label: 'Dyverse',
    value: 'Dyverse',
  },
];

export const userLevelOptionsData = [
  {
    label: 'Account Manager',
    value: 'Account Manager',
  },
  {
    label: 'Account Coordinator',
    value: 'Account Coordinator',
  },
  {
    label: 'Client',
    value: 'Client',
  },
  {
    label: 'Super Admin',
    value: 'Super Admin',
  },
];

export const ownerOptionsData = [
  {
    label: 'Avenue5',
    value: 'Avenue5',
  },
  {
    label: 'Greystar',
    value: 'Greystar',
  },
  {
    label: 'Lerner',
    value: 'Lerner',
  },
];
