// kaistore.js
import { defineStore } from 'pinia';

export const useKaiStore = defineStore('kai', {
  state: () => ({
    currentInputType: 'unknown',
    lastMessage: null,
  }),

  actions: {
    setInputType(type) {
      console.log('Setting input type:', type);
      this.currentInputType = type;
    },

    setLastMessage(message) {
      console.log('Setting last message:', message);
      this.lastMessage = message;
    },
  },

  getters: {
    isNaturalLanguage: (state) => state.currentInputType === 'natural language',
    isSearch: (state) => state.currentInputType === 'search',
  },
});
