<!-- BudgetsPageHeader.vue -->
<script setup>
import { computed } from 'vue';
import AppIconGridview from '@/components/svg/AppIconGridview.vue';
import AppIconAnalytics from '@/components/svg/AppIconAnalytics.vue';
import BudgetsIconDotsMenu from '@/components/budgets/page-header-components/IconDotsMenu.vue';
import AppBreadcrumbs from '@/components/ui/AppBreadcrumbs.vue';
import { useBudgetStore } from '@/stores/BudgetStore';

const budgetStore = useBudgetStore();

function toggleView(view) {
  budgetStore.toggleView(view);
}

// Root breadcrumb action -> calls resetFilters()
function goToBudgetsRoot() {
  budgetStore.resetFilters();
}

// Build breadcrumb items from "applied" states
const breadcrumbItems = computed(() => {
  const items = [
    {
      text: 'Reset',
      action: goToBudgetsRoot, // calls resetFilters()
    },
  ];

  if (budgetStore.appliedPropertyName) {
    items.push({ text: budgetStore.appliedPropertyName });
  }

  if (budgetStore.appliedBudgetName) {
    items.push({ text: budgetStore.appliedBudgetName });
  }

  if (budgetStore.appliedYear) {
    items.push({ text: budgetStore.appliedYear });
  }

  return items;
});
</script>

<template>
  <div class="styles_pageHeader__section">
    <div class="styles_pageHeader__container">
      <!-- Left side of page header -->
      <div>
        <!-- Only show breadcrumbs if searchPerformed is true -->
        <transition name="fade-breadcrumbs">
          <AppBreadcrumbs v-if="budgetStore.searchPerformed" :items="breadcrumbItems" class="breadcrumbs" />
        </transition>
        <h3 class="page-title">Budgets</h3>
      </div>

      <!-- Right side of page header -->
      <div class="styles_pageHeader__icon-container">
        <div class="budgets-view-options-container">
          <AppIconGridview @click="toggleView('table')" :isActive="budgetStore.currentBudgetView === 'table'" />
          <AppIconAnalytics @click="toggleView('analytics')" :isActive="budgetStore.currentBudgetView === 'analytics'" />
        </div>
        <BudgetsIconDotsMenu />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../styles/settings/mixins';
@import '../../styles/settings/themes';

.budgets-view-options-container {
  width: 70px;
  height: 32px;
  padding: 5px;
  gap: 22px;
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 7px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: var(--primary-color);
    transition: all 0.4s;
  }
}

.fade-breadcrumbs-enter-active,
.fade-breadcrumbs-leave-active {
  transition: opacity 0.3s ease;
}
.fade-breadcrumbs-enter-from,
.fade-breadcrumbs-leave-to {
  opacity: 0;
}
</style>
