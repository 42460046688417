// kaiWebSocket.js
export class KaiWebSocketService {
  constructor() {
    this.ws = null;
    this.messageHandler = null;
    this.statusHandler = null;
    this.errorHandler = null;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
    this.connecting = false;
    this.messageQueue = [];
    this.isReady = false;
  }

  onMessage(handler) {
    this.messageHandler = handler;
  }

  onStatus(handler) {
    this.statusHandler = handler;
  }

  onError(handler) {
    this.errorHandler = handler;
  }

  async connect() {
    if (this.connecting) return;
    this.connecting = true;

    try {
      // Close existing connection if any
      if (this.ws) {
        this.ws.close();
        this.ws = null;
      }

      this.ws = new WebSocket('ws://107.170.57.204:8000/ws');

      this.ws.onopen = () => {
        this.connecting = false;
        this.reconnectAttempts = 0;
        this.isReady = true;

        if (this.statusHandler) {
          this.statusHandler('connected');
        }

        // Process any queued messages
        while (this.messageQueue.length > 0) {
          const queuedMessage = this.messageQueue.shift();
          this.sendMessage(queuedMessage);
        }
      };

      this.ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (this.messageHandler) {
            this.messageHandler(data);
          }
        } catch (error) {
          if (this.errorHandler) {
            this.errorHandler(error);
          }
        }
      };

      this.ws.onclose = (event) => {
        this.connecting = false;
        if (this.statusHandler) {
          this.statusHandler('disconnected');
        }
        this.attemptReconnect();
      };

      this.ws.onerror = (error) => {
        this.connecting = false;
        if (this.errorHandler) {
          this.errorHandler(error);
        }
      };
    } catch (error) {
      this.connecting = false;
      if (this.errorHandler) {
        this.errorHandler(error);
      }
      this.attemptReconnect();
    }
  }

  async sendMessage(message) {
    if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
      this.messageQueue.push(message);
      await this.connect();
      return;
    }

    const formattedMessage = {
      message: message?.toString() || '',
      client_id: this.generateClientId(),
      timestamp: new Date().toISOString(),
    };

    try {
      this.ws.send(JSON.stringify(formattedMessage));
    } catch (error) {
      if (this.errorHandler) {
        this.errorHandler(error);
      }
    }
  }

  generateClientId() {
    return `kyzen_${Math.random().toString(36).substr(2, 9)}`;
  }

  async attemptReconnect() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++;
      setTimeout(
        () => {
          this.connect();
        },
        1000 * Math.pow(2, this.reconnectAttempts)
      );
    }
  }

  handleReconnect(event) {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      const timeout = Math.min(1000 * Math.pow(2, this.reconnectAttempts), 10000);
      this.reconnectAttempts++;

      setTimeout(() => {
        if (this.statusHandler) {
          this.statusHandler('reconnecting');
        }
        this.connect();
      }, timeout);
    } else {
      console.error('Max reconnection attempts reached');
    }
  }

  disconnect() {
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
    this.connecting = false;
    this.messageHandler = null;
    this.statusHandler = null;
    this.errorHandler = null;
  }
}
