// SettingsStore.js
import { defineStore } from 'pinia';
import { properties, goals } from '@/data/settings.js';
import { markRaw, defineAsyncComponent } from 'vue';

// Integration icons
const AppIconGoogleAnalytics = markRaw(defineAsyncComponent(() => import('@/components/svg/AppIconGoogleAnalytics.vue')));
const AppIconGoogleAds = markRaw(defineAsyncComponent(() => import('@/components/svg/AppIconGoogleAds.vue')));
const AppGBPIcon = markRaw(defineAsyncComponent(() => import('@/components/svg/AppGBPIcon.vue')));
const AppGTMIcon = markRaw(defineAsyncComponent(() => import('@/components/svg/AppGTMIcon.vue')));
const AppIconFacebookAds = markRaw(defineAsyncComponent(() => import('@/components/svg/AppIconFacebook.vue')));
const AppIconKyzen = markRaw(defineAsyncComponent(() => import('@/components/svg/AppIconKyzen.vue')));
const AppIconSlack = markRaw(defineAsyncComponent(() => import('@/components/svg/AppIconSlack.vue')));
const AppIconChatMeter = markRaw(defineAsyncComponent(() => import('@/components/svg/AppIconChatMeter.vue')));

export const useSettingsStore = defineStore('SettingsStore', {
  state: () => ({
    defaultState: {
      selectedProperty: '',
      selectedSetting: '',
      currentView: '',
    },
    // Current selection (not yet applied)
    selectedProperty: '',
    selectedSetting: '',
    currentView: '',

    // Applied filters for Breadcrumbs / final state
    appliedProperty: '',
    appliedSetting: '',

    isLoading: false,
    searchPerformed: false,

    // Complete settings list
    settings: [...properties, ...goals],

    // Integrations data
    integrations: [
      {
        integration_icon: AppIconGoogleAnalytics,
        integration_name: 'Google Analytics 4',
        integration_description: 'Google Analytics is the next generation of Analytics which collects event-based data from both websites and apps.',
        sync_message: 'Google Analytics is currently connected',
        integration_sync_status: true,
        last_edited: 'Mar 8, 2024',
        tag_id: 'G-XKN1QXGX49',
        id: 1,
      },
      {
        integration_icon: AppIconGoogleAds,
        integration_name: 'Google Ads',
        integration_description:
          'Google Ads is a product that can help you promote your business, sell items or services, raise awareness, and drive visitors to your site. Set your budget and start measuring the impact of your ad.',
        sync_message: 'Google Ads is currently connected',
        integration_sync_status: true,
        last_edited: 'Mar 8, 2024',
        id: 2,
      },
      {
        integration_icon: AppGBPIcon,
        integration_name: 'Google Business Profile',
        integration_description:
          'Your Business Profile is a free tool that allows you to take charge of the way your business appears on Google Search and Maps.',
        sync_message: 'Google Business Profile is currently connected',
        integration_sync_status: true,
        last_edited: 'Mar 8, 2024',
        id: 3,
      },
      {
        integration_icon: AppGTMIcon,
        integration_name: 'Google Tag Manager',
        integration_description:
          'Google Tag Manager is a tag management system that lets you configure and instantly deploy tags on your website or mobile app from an easy-to-use web-based interface.',
        sync_message: 'Google Tag Manager is currently connected',
        integration_sync_status: true,
        last_edited: 'Mar 8, 2024',
        tag_id: 'GTM-H6G2XC',
        id: 4,
      },
      {
        integration_icon: AppIconFacebookAds,
        integration_name: 'Meta Ads',
        integration_description: 'Connect your Facebook ad account to Kyzen to track and manage your ads.',
        sync_message: 'Facebook (Meta) is not currently connected',
        integration_sync_status: false,
        last_edited: 'Mar 8, 2024',
        id: 5,
      },
      {
        integration_icon: AppIconKyzen,
        integration_name: 'Kyzen API',
        integration_description:
          'Portfolio and per property budget reporting, spend recommendations, and accurate cost-per-lease reporting are all at your fingertips.',
        sync_message: 'Kyzen is currently connected',
        integration_sync_status: true,
        last_edited: 'Mar 8, 2024',
        id: 6,
      },
      {
        integration_icon: AppIconSlack,
        integration_name: 'Slack',
        integration_description: 'Automatically connect to Slack to receive notifications.',
        sync_message: 'An error has occurred for the Slack integration. Please contact support.',
        integration_sync_status: 'error',
        last_edited: 'Mar 8, 2024',
        id: 7,
      },
      {
        integration_icon: AppIconChatMeter,
        integration_name: 'Chatmeter API',
        integration_description:
          'Chatmeter API allows businesses to manage their online presence and reputation across various locations by providing centralized SEO, review management, and listing accuracy insights.',
        sync_message: 'Chat Meter is currently connected',
        integration_sync_status: true,
        last_edited: 'Mar 19, 2024',
        id: 8,
      },
    ],
  }),

  getters: {
    filteredData: (state) => {
      // If no search performed yet, return entire dataset
      if (!state.searchPerformed) return state.settings;

      // Otherwise, filter by the immediate selectedProperty / selectedSetting
      return state.settings.filter((setting) => {
        const matchesProperty = state.selectedProperty ? setting.name.includes(state.selectedProperty) : true;
        const matchesSetting = state.selectedSetting ? setting.name.includes(state.selectedSetting) : true;
        return matchesProperty && matchesSetting;
      });
    },

    // A getter to easily access all reactive integrations
    allIntegrations: (state) => state.integrations,
  },

  actions: {
    fetchSettings() {
      this.isLoading = true;
      setTimeout(() => {
        this.resetSettings();
        this.isLoading = false;
      }, 1800);
    },

    // For immediate searching/selection (if you want to let user pick them before final apply)
    handleSearch(selectedProperty, selectedSetting) {
      this.selectedProperty = selectedProperty;
      this.selectedSetting = selectedSetting;
      this.currentView = selectedSetting;
      this.searchPerformed = true;
    },

    // Resets everything to default
    resetSearch() {
      this.searchPerformed = false;
      this.isLoading = true;
      setTimeout(() => {
        this.selectedProperty = this.defaultState.selectedProperty;
        this.selectedSetting = this.defaultState.selectedSetting;
        this.currentView = this.defaultState.currentView;

        // Also reset applied states
        this.appliedProperty = '';
        this.appliedSetting = '';

        this.searchPerformed = false;
        this.resetSettings();
        this.isLoading = false;
      }, 700);
    },

    // Helper to restore initial settings array
    resetSettings() {
      this.settings = [...properties, ...goals];
    },

    // Finalize the selected filters so that your breadcrumbs & final state reflect them
    applyFilters() {
      // Force a quick toggle to ensure Vue sees a re-render
      this.searchPerformed = false;

      // Actually set your newly applied data for breadcrumbs
      this.appliedProperty = this.selectedProperty;
      this.appliedSetting = this.selectedSetting;

      // Trigger a state patch to show that we've re-applied, updating currentView as well
      this.$patch({
        searchPerformed: true,
        currentView: this.selectedSetting,
      });
    },
  },
});
