<script setup>
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { usePropertiesStore } from '@/stores/PropertiesStore';
import AppBreadcrumbs from '@/components/ui/AppBreadcrumbs.vue';
import AppOverlayMenu from '@/components/ui/AppOverlayMenu.vue';
import AppIconDotsVertical from '@/components/svg/AppIconDotsVertical.vue';
import AppIconBoxWrapper from '@/components/wrappers/AppIconBoxWrapper.vue';
import AppIconDetailView from '@/components/svg/AppIconDetailView.vue';
import AppIconGridview from '@/components/svg/AppIconGridview.vue';
import AppIconListView from '@/components/svg/AppIconListView.vue';

// 1. Access the PropertiesStore
const propertiesStore = usePropertiesStore();

// 2. Overlay logic
const isOverlayVisible = ref(false);
const target = ref(null);

function toggleOverlay() {
  isOverlayVisible.value = !isOverlayVisible.value;
}

onClickOutside(target, () => {
  if (isOverlayVisible.value) {
    isOverlayVisible.value = false;
  }
});

function handleOptionClicked(option) {
  console.log(`Option ${option.text} clicked`);
}

// 3. Root link action: reset store filters
function goToPropertiesRoot() {
  propertiesStore.resetFilters();
}

// 5. Compute breadcrumb items based on "applied" state
const breadcrumbItems = computed(() => {
  // Always start with "Properties" as the root breadcrumb
  const items = [
    {
      text: 'Reset',
      action: goToPropertiesRoot,
    },
  ];

  // If a search query was applied, show it
  if (propertiesStore.appliedSearchQuery) {
    items.push({ text: propertiesStore.appliedSearchQuery });
  }

  // If multiple properties were applied, show how many
  if (propertiesStore.appliedSelectedProperties?.length) {
    items.push({ text: `${propertiesStore.appliedSelectedProperties.length} selected` });
  }

  // If orderBy was applied, include it as the last breadcrumb item
  if (propertiesStore.appliedOrderBy) {
    items.push({ text: `${propertiesStore.appliedOrderBy}` });
  }

  return items;
});
</script>

<template>
  <div class="styles_pageHeader__section">
    <div class="styles_pageHeader__container">
      <!-- Left side of page header -->
      <div>
        <!-- Only render breadcrumbs if a search has been performed -->
        <transition name="fade-breadcrumbs">
          <AppBreadcrumbs v-if="propertiesStore.propertiesSearchPerformed" :items="breadcrumbItems" />
        </transition>
        <!-- The page title always remains -->
        <h3 class="page-title">Properties</h3>
      </div>

      <!-- Right side of page header -->
      <div class="styles_pageHeader__icon-container">
        <!-- View style toggles for grid, detail, list, etc. -->
        <div class="properties-view-options-container">
          <AppIconDetailView @click="propertiesStore.toggleView('detail-grid')" :isActive="propertiesStore.currentPropertyView === 'detail-grid'" />
          <AppIconListView @click="propertiesStore.toggleView('list')" :isActive="propertiesStore.currentPropertyView === 'list'" />
        </div>

        <!-- Dots menu overlay -->
        <div ref="target">
          <AppIconBoxWrapper @click="toggleOverlay">
            <AppIconDotsVertical />
            <AppOverlayMenu
              v-if="isOverlayVisible"
              @option-clicked="handleOptionClicked"
              :options="['new-property', 'export-to-csv', 'settings']"
              bodyWidth="160px"
            >
              <template #new-property>
                <div>New property</div>
              </template>
              <template #export-to-csv>
                <div>Export list to CSV</div>
              </template>
              <template #settings>
                <div>View preferences</div>
              </template>
            </AppOverlayMenu>
          </AppIconBoxWrapper>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'src/styles/settings/_mixins.scss';
@import 'src/styles/settings/_themes.scss';

// Container styling for the grid/detail/list view icons
.properties-view-options-container {
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  gap: 15px;
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 7px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: var(--primary-color);
    transition: all 0.4s;
  }
}

.fade-breadcrumbs-enter-active,
.fade-breadcrumbs-leave-active {
  transition: opacity 0.3s ease;
}
.fade-breadcrumbs-enter-from,
.fade-breadcrumbs-leave-to {
  opacity: 0;
}
</style>
