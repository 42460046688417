<!-- PropertyLinks.vue -->
<script setup>
import AppOverlayMenu from '@/components/ui/AppOverlayMenu.vue';

// Define the props the component accepts
const props = defineProps({
  propertyId: Number,
});

function handleOptionClicked(option) {
  console.log(`Option ${option} clicked for property ${props.propertyId}`);
  // Add logic here to handle different options
}
</script>

<template>
  <div class="property-links">
    <AppOverlayMenu
      @option-clicked="handleOptionClicked"
      :options="['dashboardsOption', 'reportsOption', 'budgetsOption', 'alertsOption', 'settingsOption']"
    >
      <template #dashboardsOption>
        <a class="overlay-menu-link" href="/dashboards">
          <p class="link-text">Dashboards</p>
        </a>
      </template>
      <template #reportsOption>
        <a class="overlay-menu-link" href="/reports">
          <p class="link-text">Reports</p>
        </a>
      </template>
      <template #budgetsOption>
        <a class="overlay-menu-link" href="/budgets">
          <p class="link-text">Budgets</p>
        </a>
      </template>
      <template #alertsOption>
        <a class="overlay-menu-link" href="/alerts">
          <p class="link-text">Alerts</p>
        </a>
      </template>
      <template #settingsOption>
        <a class="overlay-menu-link" href="/settings">
          <p class="link-text">Settings</p>
        </a>
      </template>
    </AppOverlayMenu>
  </div>
</template>

<style scoped lang="scss">
.property-links {
  position: relative;

  .overlay-menu-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: var(--primary-text-color);
    text-decoration: none;
    cursor: pointer;
    font-weight: 400;
    opacity: 0.75;
    border-radius: 7px;

    .link-text {
      padding: 5px 30px 5px 5px;
    }

    &:hover {
      opacity: 100;
      transition: all 0.3s ease;
      background: var(--property-metric-bg);
      background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);

      .nav-icon {
        color: var(--primary-color);
      }
    }
  }
}

:deep(.overlay-menu ul) {
  padding: 7px;
}

:deep(.overlay-menu li) {
  opacity: 100;
  padding: 5px 10px;

  &:hover {
    opacity: 1;
    background: transparent;
    cursor: default;
    transition: none;
  }
}
</style>
