<!-- AppSingleSelector.vue -->
<script setup>
// Imports...

import { computed, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import AppIconAngleDown from '@/components/svg/AppIconAngleDown.vue';
import AppSearch from '@/components/ui/search/AppSearch.vue';

// Logic...
const props = defineProps({
  title: String,
  placeholder: String,
  options: {
    type: Array,
    default: () => [],
  },
  modelValue: [String, Number],
  showSearch: {
    type: Boolean,
    default: false,
  },
  bodyWidth: {
    type: String,
    default: 'auto',
  },
  footerLink: String,
  footerText: String,
  open: {
    type: Boolean,
    default: undefined,
  },
});
const emit = defineEmits(['update:modelValue', 'update:open']);

// State and logic
const filterValue = ref('');
const showBody = ref(false);
const target = ref(null);

const filteredOptions = computed(() => {
  if (!filterValue.value) {
    return props.options;
  }
  return props.options.filter((option) => option.label.toLowerCase().includes(filterValue.value.toLowerCase()));
});

const selectedLabel = computed(() => {
  const option = props.options.find((opt) => opt.value === props.modelValue);
  return option ? option.label : '';
});

function handleSelectedOption(option) {
  emit('update:modelValue', option.value);
  if (props.open !== undefined) {
    emit('update:open', false);
  } else {
    showBody.value = false;
  }
}

function handleClick() {
  if (props.open !== undefined) {
    emit('update:open', !props.open); // Emit only when open prop is provided
  } else {
    showBody.value = !showBody.value; // Otherwise, change showBody ref
  }
}

onClickOutside(target, () => {
  if (props.open !== undefined) {
    emit('update:open', false); // Emit only when open prop is provided
  } else {
    showBody.value = false; // Otherwise, change showBody ref
  }
});

// clear field method
function clear() {
  emit('update:modelValue', '');
  filterValue.value = '';
}

// exposed for components to use it
defineExpose({
  clear,
});
</script>

<template>
  <div class="app-single-selector-container" ref="target">
    <div :class="{ 'is-active': showBody }" @click="handleClick">
      <div class="app-single-selector-header">
        <label for="" v-if="title" class="input-label">{{ title }}</label>
        <h5 class="placeholder" v-if="!modelValue">
          {{ placeholder }}
          <AppIconAngleDown class="app-dropdown-icon" />
        </h5>
        <h5 class="selectedText" v-else>
          {{ selectedLabel }}
          <AppIconAngleDown class="app-dropdown-icon" />
        </h5>
      </div>
    </div>

    <div class="app-single-selector-body" :style="{ width: bodyWidth }" v-if="showBody || props.open">
      <div class="app-single-selector-search-container">
        <AppSearch v-if="showSearch" placeholder="Search..." v-model="filterValue" />
      </div>
      <ul class="options-container">
        <template v-if="filteredOptions.length > 0">
          <li v-for="(option, index) in filteredOptions" :key="index">
            <button class="options-style" :value="option.value" @click="handleSelectedOption(option)">
              {{ option.label }}
            </button>
          </li>
        </template>
        <li v-else>
          <span class="no-results">No results found</span>
        </li>
      </ul>
      <div v-if="$slots.footer" class="app-single-selector-footer-container">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-single-selector-container {
  position: relative;
}

.modal-items {
  .app-single-selector-container {
    padding: 10px 0;

    &:first-child {
      padding-top: 0;
    }
  }
}

.app-single-selector-header {
  cursor: pointer;
}

.placeholder,
.selectedText {
  font-size: 16px;
  position: relative;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 12px;
  height: 45px;
  line-height: 42px;
  padding: 0 40px 0 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;

  &.placeholder {
    color: var(--secondary-color);
  }

  &.selectedText {
    color: var(--primary-text-color);
  }

  &:hover {
    border-color: var(--primary-color);
    transition: 0.4s ease;
  }
}

.app-dropdown-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
}

.app-single-selector-body {
  margin-top: -10px;
  margin-left: -4px;
  padding: 18px 18px 14px 18px;
  z-index: 10;
  position: absolute;
  top: 100%;
  left: 180px;
  transform: translateX(-50%);
  background: var(--dropdown-bg);
  border-radius: 14px;
  border: 1.677px solid var(--secondary-color);
  box-shadow: 0 31px 20px rgba(0, 0, 0, 0.25);

  .search-alt {
    margin: 0px 0 13px 0;
    // padding: 7px 18px 0 18px;
  }
}

.app-single-selector-search-container {
  //padding: 0 18px 0 0;
  padding: 0;
}

.options-container {
  list-style: none;
  margin: 0;
  max-height: 300px;
  overflow-y: auto;

  /* Custom scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    width: 9px;
    transition: width 0.5s ease, background-color 0.5s ease;
    /* Increase transition duration */
    scrollbar-gutter: auto;
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
    background: var(--property-card-bg);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(var(--secondary-color-rgb), 0.4);
    transition: background 0.3s ease;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--secondary-color-rgb), 1);
  }
}

.options-style {
  font-size: 16px;
  background: none;
  border: none;
  padding: 7px 10px;
  border-radius: 8px;
  display: block;
  width: 100%;
  text-align: left;
  transition: background 0.4s, color 0.4s;

  &:hover {
    background: var(--property-metric-bg);
    background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);
  }
}

.app-single-selector-footer-container {
  border-top: var(--border-size) solid var(--secondary-color);
  margin-left: -18px;
  /* match the negative margin to the body's padding */
  margin-right: -18px;
  /* apply to right as well */
  margin-top: 5px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 8px;
}

.input-label {
  display: block;
  font-weight: 400;
  padding-left: 14px;
  font-size: 13px;
  line-height: 1;
  color: var(--secondary-color);
  margin-bottom: 5px;
}

// Adjusting this label color for modals
.modal-content {
  .input-label {
    color: var(--primary-text-color);
  }
}

.no-results {
  padding: 0px 8px;
}
</style>
