<!-- AppPropertiesSelector.vue -->
<script setup>
// Imports...
import { onMounted, ref, computed, defineEmits, watch } from 'vue';
import AppMultiSelector from '@/components/ui/selects/AppMultiSelector.vue';
import AppSearch from '@/components/ui/search/AppSearch.vue';
import useSearch from '@/composables/useSearch';
import AppCheckboxButton from '@/components/ui/buttons/AppCheckboxButton.vue';
import { usePropertiesStore } from '@/stores/PropertiesStore';

// Define emits
const emit = defineEmits(['update:selectedOptionText']);

// Setup property store and local data
const propertiesStore = usePropertiesStore();
const originalProperties = ref([]);
const selectedOption = ref([]);

// On mount, fetch property data
onMounted(async () => {
  await propertiesStore.getProperties();
  originalProperties.value = propertiesStore.originalProperties;
});

// Returns either the single name or "Multiple (#)"
const selectedOptionText = computed(() => {
  if (selectedOption.value.length === 0) {
    return ''; // nothing selected, show placeholder externally
  } else if (selectedOption.value.length === 1) {
    return selectedOption.value[0].name; // single property name
  } else {
    return `Multiple (${selectedOption.value.length})`;
  }
});

// Watch the computed text for changes and emit
watch(selectedOptionText, (newVal) => {
  emit('update:selectedOptionText', newVal);
});

// Hook up search composable
const { searchValue, filteredOptions } = useSearch(originalProperties);

// Transform data as needed
function transformOption(option) {
  const { attributes, ...rest } = option;
  return {
    ...rest,
    ...attributes,
    name: attributes.name,
  };
}

// Handle selection toggling
function handleSelectedOption(option) {
  const index = selectedOption.value.findIndex((cat) => cat.id === option.attributes.id);
  const transformedOption = transformOption(option);

  if (index > -1) {
    selectedOption.value.splice(index, 1);
  } else {
    selectedOption.value.push(transformedOption);
  }
}

// Check if category is selected
function isChecked(categoryId) {
  return selectedOption.value.some((prop) => prop.id === categoryId);
}
</script>

<template>
  <AppMultiSelector title="Properties" v-model:selectedOptions="selectedOption" placeholder="Select one or more properties">
    <template #body>
      <AppSearch placeholder="Search..." v-model="searchValue" @click.stop />
      <div v-if="!filteredOptions.length" class="no-results">
        We could not find any results based on your search. Please adjust your filters and search again.
      </div>
      <ul class="list">
        <li class="list-item" v-for="category in filteredOptions" :key="category.id">
          <div class="list-item-contents-wrapper">
            <AppCheckboxButton
              :label="category.attributes.name"
              :is-check="true"
              :is-checked="isChecked(category.attributes.id)"
              @checkboxClicked="handleSelectedOption(category)"
            />
          </div>
        </li>
      </ul>
    </template>
  </AppMultiSelector>
</template>

<style scoped lang="scss">
@import 'src/styles/settings/_mixins.scss';

.list {
  list-style: none;
  padding: 10px 0;
  margin: 5px 0 15px 0;
  max-height: 300px;
  overflow-y: auto;
  /* Custom scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    width: 9px;
    transition:
      width 0.5s ease,
      background-color 0.5s ease;
    scrollbar-gutter: auto;
  }

  &::-webkit-scrollbar-track {
    background: var(--property-card-bg);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(var(--secondary-color-rgb), 0.4);
    transition: background 0.3s ease;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--secondary-color-rgb), 1);
  }
}

.list-item-contents-wrapper {
  padding: 2px 4px;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background: var(--property-metric-bg);
    background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);
  }
}
</style>
