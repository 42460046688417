<!-- Users/TableLayout.vue -->
<script setup>
import { useTableSortingStore } from '@/stores/TableSortingStore';
import { computed } from 'vue';
import defaultAvatar from '@/assets/images/default-avatar.png';
import SimpleBar from 'simplebar-vue';
import useTableStyles from '@/composables/useTableStyles';
import { useUsersStore } from '@/stores/UsersStore';
import AppIconSortUp from '@/components/svg/AppIconSortUp.vue';
import AppIconSortDown from '@/components/svg/AppIconSortDown.vue';

const usersStore = useUsersStore();
const tableStore = useTableSortingStore();
const tableId = 'usersTable';

// Define the columns array to be used in the table rendering
const columns = [
  { key: 'email', displayName: 'Username' },
  { key: 'client', displayName: 'Client' },
  { key: 'user_level', displayName: 'User Level' },
  { key: 'date_added', displayName: 'Date Added' },
  { key: 'last_sign_in', displayName: 'Last Sign In' },
];

const handleSort = (dataKey) => tableStore.setSorting(tableId, dataKey);
const sortedData = computed(() => tableStore.getSortedData(tableId, usersStore.users));

const { cellStyle, getCellClass } = useTableStyles(usersStore.users, 13, {
  tableId,
  tableStore,
});

const getSortIcon = (dataKey) => {
  const direction = tableStore.arrowDirection(tableId, dataKey);
  if (direction === 'up') {
    return AppIconSortUp;
  } else if (direction === 'down') {
    return AppIconSortDown;
  }
  return null; // no icon should show by default when nothing is sorted yet
};

const getImageUrl = (avatarUrl) => (avatarUrl ? require('@/assets/images/' + avatarUrl) : defaultAvatar);
</script>

<template>
  <div class="wrapper">
    <SimpleBar>
      <div class="div-column-row">
        <div
          v-for="column in columns"
          :key="column.key"
          :style="cellStyle"
          :class="['header-cell sortable', getCellClass(column.key)]"
          @click="handleSort(column.key)"
        >
          <div class="header-cell-content">
            {{ column.displayName }}
            <component :is="getSortIcon(column.key)" v-if="getSortIcon(column.key)" class="sort-styles" />
          </div>
        </div>
      </div>
      <div v-if="sortedData.length > 0">
        <div class="row-container" v-for="row in sortedData" :key="row">
          <div v-for="column in columns" :key="column.key" :style="cellStyle" :class="['cell', getCellClass(column.key)]">
            <div class="cell-content">
              <img v-if="column.key === 'email'" :src="getImageUrl(row.avatar)" alt="avatar" class="avatar" />
              <span v-if="column.key === 'email'">{{ row[column.key] }}</span>
              <span v-else-if="column.key === 'user_level'" class="pill">{{ row[column.key] }}</span>
              <span v-else>{{ row[column.key] }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="cell no-results">No results</div>
    </SimpleBar>
  </div>
</template>

<style scoped lang="scss">
@import '@/styles/settings/mixins';
@import 'simplebar-vue/dist/simplebar.min.css';

.wrapper {
  display: flex;
  flex-direction: column;
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 18px;
  overflow-x: auto;
}

:deep(.simplebar-scrollbar::before) {
  background-color: var(--secondary-color);
}

.div-column-row {
  display: flex;
  min-width: max-content;
  border-bottom: var(--border-size) solid var(--secondary-color);
}

.header-cell {
  display: flex;
  align-items: center;
  flex: 1 0 var(--cell-base-size);
  min-width: var(--cell-base-size);
  font-weight: 600;
  font-size: 16px;
  color: var(--secondary-color);
  text-align: left;
  transition: color 0.3s ease;

  &.active-column {
    .header-cell-content {
      color: var(--primary-color);
    }
  }

  .header-cell-content {
    padding: 15px;
  }
}

.sort-styles {
  align-self: start;
  vertical-align: middle;
}

.sortable:hover {
  cursor: pointer;
}

.row-container {
  display: flex;
  min-width: max-content;
  border-bottom: var(--border-size) solid var(--secondary-color);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: hsla(216, 16%, 45%, 0.1);
  }

  &:last-child {
    border-bottom: none;
  }
}

.cell {
  display: flex;
  align-items: center;
  flex: 1 0 var(--cell-base-size);
  min-width: var(--cell-base-size);
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  transition: background-color 0.3s ease;

  &:first-child {
    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .cell-content {
    padding: 15px;
  }
}

.no-results {
  padding: 15px;
}

.pill {
  border: 1px solid var(--primary-color);
  border-radius: 50px;
  margin: 0;
  padding: 5px 15px;
  display: inline-block;
  font-size: 0.65em;
  color: var(--primary-text-color);
}
</style>
