// alertsStore.js
import { defineStore } from 'pinia';
import alertsDataJson from '@/data/alertsData.json';
import { alertOrderByOptionsType } from '@/data/multiUseData';

export const useAlertsStore = defineStore({
  id: 'alertsStore',
  state: () => ({
    // "Instant" filter states
    selectedKeyword: '', // typed search query that hasn’t been applied yet
    selectedType: '',
    selectedOrderBy: '',

    // "Applied" or "committed" states for final display (breadcrumbs, etc.)
    appliedKeyword: '',
    appliedType: '',
    appliedOrderBy: '',

    // Data and loading flags
    alertsData: [], // all alerts loaded from JSON
    filteredAlerts: [], // store the final filtered results if you like
    isLoading: false,
    initialLoad: false,

    // indicates whether we have applied filters at least once
    searchPerformed: false,
  }),

  getters: {
    // Return a final computed array from the "applied" states
    // If you want to store the final in `alertsData`, you can do that too
    filteredData: (state) => {
      let filteredData = [...state.alertsData];

      // Only filter if searchPerformed is true
      if (state.searchPerformed) {
        // Filter by type
        if (state.appliedType) {
          filteredData = filteredData.filter((alert) => alert.type === state.appliedType);
        }

        // Filter by search keyword across any fields
        if (state.appliedKeyword) {
          const kw = state.appliedKeyword.toLowerCase();
          filteredData = filteredData.filter((alert) => Object.values(alert).some((value) => value.toString().toLowerCase().includes(kw)));
        }

        // Apply sorting
        if (state.appliedOrderBy) {
          const selectedOption = alertOrderByOptionsType.find((option) => option.label === state.appliedOrderBy);
          if (selectedOption) {
            const { value, key } = selectedOption;
            filteredData.sort((a, b) => {
              if (value === 'date') {
                return new Date(b[key]) - new Date(a[key]);
              } else if (value === 'text') {
                return a[key].localeCompare(b[key]);
              }
            });
          }
        }
      }

      return filteredData;
    },
  },

  actions: {
    // Called once to load alerts
    getAlerts() {
      this.initialLoad = true;
      setTimeout(() => {
        this.selectedOrderBy = '';
        this.alertsData = alertsDataJson.data;
        this.initialLoad = false;
      }, 1000);
    },

    // “Instant” selection. This sets up what user *wants* to search.
    handleSearch(selectedKeyword, selectedOrderBy, selectedType = '') {
      this.selectedKeyword = selectedKeyword;
      this.selectedOrderBy = selectedOrderBy;
      this.selectedType = selectedType;
    },

    // The final commit of filters:
    applyFilters() {
      this.isLoading = true;

      // Toggle searchPerformed off -> on so watchers or templates see a re-render
      this.searchPerformed = false;

      // Commit “instant” states to “applied” states
      this.appliedKeyword = this.selectedKeyword;
      this.appliedOrderBy = this.selectedOrderBy;
      this.appliedType = this.selectedType;

      setTimeout(() => {
        // Now that we’ve updated applied states, set searchPerformed back to true
        this.searchPerformed = true;

        // If you want to store final data in alertsData or filteredAlerts,
        // you can do it here:
        this.alertsData = alertsDataJson.data; // load raw data again
        // Force the getter to pick up new data
        const finalFiltered = this.filteredData;
        // Optionally store the final result in a dedicated var:
        this.filteredAlerts = finalFiltered;

        this.isLoading = false;
      }, 1000);
    },

    // Clears everything (both selected & applied states)
    resetSearch() {
      this.searchPerformed = false;
      this.isLoading = true;
      setTimeout(() => {
        this.selectedKeyword = '';
        this.selectedType = '';
        this.selectedOrderBy = '';

        this.appliedKeyword = '';
        this.appliedType = '';
        this.appliedOrderBy = '';

        this.searchPerformed = false;
        this.alertsData = alertsDataJson.data;
        this.filteredAlerts = [];
        this.isLoading = false;
      }, 700);
    },
  },
});
