// Users Store
import { defineStore } from 'pinia';
import userDataJson from '@/data/newUserData.json';

export const useUsersStore = defineStore({
  id: 'usersStore',
  state: () => ({
    selectedClient: '', //Temp state value to store the selected client
    selectedUserLevel: '', // Temp state value to store the selected user level
    selectedOrderBy: '',
    selectedKeyword: '',
    searchPerformed: false,
    users: [],
    isLoading: false,
    initialLoad: false,
  }),
  getters: {
    filteredData: (state) => {
      let filteredData = [...state.users];

      if (state.searchPerformed) {
        filteredData = filteredData.filter(
          (user) =>
            (!state.selectedClient || user.client === state.selectedClient) &&
            (!state.selectedUserLevel || user.user_level === state.selectedUserLevel) &&
            (!state.selectedKeyword ||
              Object.values(user).some((value) => value.toString().toLowerCase().includes(state.selectedKeyword.toLowerCase())))
        );

        if (state.selectedOrderBy) {
          switch (state.selectedOrderBy) {
            case 'Last sign-in':
              filteredData.sort((a, b) => new Date(b.last_sign_in) - new Date(a.last_sign_in));
              break;
            case 'Email':
              filteredData.sort((a, b) => a.email.localeCompare(b.email));
              break;
            case 'Recent':
              filteredData.sort((a, b) => new Date(b.date_added) - new Date(a.date_added));
              break;
            default:
              break;
          }
        }
      }
      return filteredData;
    },
  },
  actions: {
    fetchUsers() {
      this.initialLoad = true;
      // Simulate API call wrapping in timeout
      setTimeout(() => {
        this.users = userDataJson.data;
        this.initialLoad = false;
      }, 1000);
    },
    handleSearch(selectedClient, selectedUserLevel, selectedOrderBy, selectedKeyword) {
      this.selectedClient = selectedClient;
      this.selectedUserLevel = selectedUserLevel;
      this.selectedOrderBy = selectedOrderBy;
      this.selectedKeyword = selectedKeyword;
      this.searchPerformed = true;
    },
    resetSearch() {
      this.selectedClient = '';
      this.selectedUserLevel = '';
      this.selectedOrderBy = '';
      this.selectedKeyword = '';
      this.searchPerformed = false;
      this.users = userDataJson.data;
    },
    applyFilters() {
      this.isLoading = true;
      this.handleSearch(this.selectedClient, this.selectedUserLevel, this.selectedOrderBy, this.selectedKeyword);
      // Simulate API call wrapping in timeout
      setTimeout(() => {
        this.users = this.filteredData;
        this.isLoading = false;
      }, 500);
    },
  },
});
