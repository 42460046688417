<!-- UsersTopFilterSection.vue -->
<script setup>
import { ref } from 'vue';
import { useGlobalStore } from '@/stores/GlobalStore';
import { useUsersStore } from '@/stores/UsersStore';

// UI Components
import AppIconAngleDownPair from '@/components/svg/AppIconAngleDownPair.vue';
import AppButton from '@/components/ui/buttons/AppButton.vue';
import AppSingleSelector from '@/components/ui/selects/AppSingleSelector.vue';
import AppVisibleFilterWrapper from '@/components/ui/AppVisibleFilterWrapper.vue';
import AppKeywordSearch from '@/components/ui/search/AppKeywordSearch.vue';

// Data for dropdowns (client, userLevel, orderBy)
import { clientOptionsData, userLevelOptionsData, orderByOptionsData } from '@/data/usersFilterOptions';

import { debounce } from '@/helpers/debounce';

// Global store for toggling filter visibility
const globalStore = useGlobalStore();
const usersStore = useUsersStore();

// we have local refs for each filter, so the store is NOT updated in real time.
const localKeyword = ref('');
const localClient = ref('');
const localUserLevel = ref('');
const localOrderBy = ref('');

const debouncedSearch = debounce(() => {
  handleSearch();
}, 500);

function handleSearch() {
  usersStore.selectedKeyword = localKeyword.value;
  usersStore.selectedClient = localClient.value;
  usersStore.selectedUserLevel = localUserLevel.value;
  usersStore.selectedOrderBy = localOrderBy.value;
  usersStore.applyFilters();
}

// Toggle top filter in mobile/responsive mode
function handleClick() {
  globalStore.toggleVisibleFilter();
}

// Reset all local states and store filters
function handleReset() {
  // Clear store
  usersStore.resetSearch();
  // Clear local states
  localKeyword.value = '';
  localClient.value = '';
  localUserLevel.value = '';
  localOrderBy.value = '';
}
</script>

<template>
  <div class="styles_topFilter__container">
    <div class="grid">
      <!-- Keyword Search (localKeyword) -->
      <div class="flex-auto">
        <AppKeywordSearch
          label="Keywords"
          placeholder="Email, name, property, etc."
          v-model="localKeyword"
          @Submit.prevent="debouncedSearch"
          @keyup.enter="debouncedSearch"
        />
      </div>

      <!-- Client Selector (localClient) -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector title="Client" placeholder="Select a client" :options="clientOptionsData" v-model="localClient" />
      </AppVisibleFilterWrapper>

      <!-- User Level -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector title="User Level" placeholder="Select a user level" :options="userLevelOptionsData" v-model="localUserLevel" />
      </AppVisibleFilterWrapper>

      <!-- Order By -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector title="Order by" placeholder="Select an option" :options="orderByOptionsData" v-model="localOrderBy" />
      </AppVisibleFilterWrapper>

      <!-- Filter Buttons -->
      <div class="filter-button-container">
        <div class="filter-clear-all" v-if="usersStore.searchPerformed" @click="handleReset">Reset</div>
        <div v-else class="filter-clear-all-placeholder"></div>

        <AppButton label="Search" class="filter-button-bottom" :isLoading="usersStore.isLoading" @click="handleSearch" />
      </div>

      <!-- Toggle Button for mobile -->
      <AppButton toggle_btn @click="handleClick">
        <AppIconAngleDownPair />
      </AppButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../styles/settings/mixins';

.grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.flex-auto {
  flex: 1 1 auto;
}

.filter-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-clear-all,
.filter-clear-all-placeholder {
  color: var(--secondary-color);
  background-color: transparent;
  border: none;
  font-size: 13px;
  text-decoration: underline;
  margin-bottom: 5px;
  height: 15px;
  visibility: hidden;
}

.filter-clear-all {
  visibility: visible;
  &:hover {
    color: var(--primary-text-color);
    cursor: pointer;
  }
}
</style>
